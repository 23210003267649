import { Employee } from "@/model/employee/employee";
import { EmployeeListItem } from "@/model/employee/employee_list_item";
import { EmployeeStatus } from "@/model/employee/employee_status";
import { Date } from "@/model/values/date";
import { Permission } from "@/model/values/permission";
import { Role } from "@/model/values/role";
import { toCamel, toCamelCase } from "@/service/convert_service";
import { Applicant } from "@/model/role/applicant";
import { Approver } from "@/model/role/approver";
import { FirstApprover } from "@/model/role/first_approver";
import { SecondApprover } from "@/model/role/second_approver";
import { roles } from "@/const/workflow_roles";
import { FunctionPermission } from "@/model/values/function_permission";
import { WorkflowPermission } from "@/model/role/workflow_permission";

export const employeeListItemFromJson = (json) => {
  const {
    employeeId,
    employeeNumber,
    firstName,
    lastName,
    email,
    permission,
    isDeleted,
    status,
    accountLockedAt,
  } = toCamel(json);

  const { workflowPermission } = toCamel(permission);
  const roles = workflowPermission.map((e) => Role.valueOf(e));
  return EmployeeListItem.valueOf({
    id: employeeId,
    employeeNumber,
    firstName,
    lastName,
    email,
    workflowPermission: WorkflowPermission.valueOf({ roles }),
    status: EmployeeStatus.valueOf(status),
    isDeleted,
    accountLockedAt: Date.valueOf(accountLockedAt),
  });
};

// applicantFromJsonでいいかも
export const employeeFromJson = (json) => {
  const {
    employeeId,
    employeeNumber,
    lastName = "",
    firstName = "",
    lastNameKana = "",
    firstNameKana = "",
    birthday,
    email,
    phoneNumber,
    permission,
    isPrimaryUser,
    isDeleted,
    notificationSettings,
    status,
  } = toCamel(json);
  const { workflowPermission, functionsPermission } = toCamel(permission);

  const roles = workflowPermission.map((e) => Role.valueOf(e));
  return Employee.valueOf({
    id: employeeId,
    employeeNumber,
    lastName,
    lastNameKana,
    firstName,
    firstNameKana,
    birthday: Date.valueOf(birthday),
    email,
    status: EmployeeStatus.valueOf(status),
    cellphoneNumber: phoneNumber,
    isPrimaryUser,
    isDeleted,
    permission: Permission.valueOf({
      workflowPermission: WorkflowPermission.valueOf({ roles }),
      functionsPermission,
    }),
    notificationSettings: toCamel(notificationSettings),
  });
};

export const employeeFromJsonAndCompany = (company, json) => {
  const {
    employeeId,
    employeeNumber,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    birthday,
    email,
    phoneNumber,
    permission,
    notificationSettings,
    isPrimaryUser,
    hasTransactionPassword,
  } = toCamel(json);
  const { workflowPermission, functionsPermission } = toCamel(permission);

  const roles = workflowPermission.map((e) => generateRole(company, e));
  return Employee.valueOf({
    id: employeeId,
    employeeNumber,
    lastName,
    lastNameKana,
    firstName,
    firstNameKana,
    birthday: Date.valueOf(birthday),
    email,
    cellphoneNumber: phoneNumber,
    permission: Permission.valueOf({
      workflowPermission: WorkflowPermission.valueOf({ roles }),
      functionsPermission: functionsPermission.map((e) =>
        FunctionPermission.valueOf(e),
      ),
    }),
    notificationSettings: notificationSettings.map((v) => toCamelCase(v)),
    isPrimaryUser,
    hasTransactionPassword,
  });
};

const generateRole = (company, workflowPermission) => {
  const { isUseWorkflow, numberOfApprovalStep } = company;
  if (isUseWorkflow && workflowPermission === roles.approver.value) {
    if (numberOfApprovalStep === 1) return Approver.valueOf(workflowPermission);
    if (numberOfApprovalStep === 2)
      return FirstApprover.valueOf(workflowPermission);
  }
  if (isUseWorkflow && workflowPermission === roles.applicant.value) {
    return Applicant.valueOf(workflowPermission);
  }
  if (isUseWorkflow && workflowPermission === roles.second_approver.value) {
    if (numberOfApprovalStep === 2)
      return SecondApprover.valueOf(workflowPermission);
  }
  return Role.valueOf(workflowPermission);
};
