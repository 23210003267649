const properties = {
  dataCount: 0,
  listItems: [],
};

export class EmployeeList {
  static valueOf(items = properties) {
    return new EmployeeList({ ...properties, ...items });
  }
  static empty() {
    return new EmployeeList();
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }

  get activeEmployeeList() {
    return this.listItems.filter((e) => e.status.isComplete);
  }
}
