const properties = {
  id: 0,
  lastName: "",
  firstName: "",
  lastNameKana: "",
  firstNameKana: "",
};
export class OperationEmployee {
  constructor(items) {
    const item = { ...properties, ...items };
    Object.assign(this, item);
  }

  static valueOf(item = properties) {
    return new OperationEmployee(item);
  }

  static empty() {
    return OperationEmployee.valueOf();
  }

  toString() {
    const { lastName, firstName } = this;
    return `${lastName}${firstName}`;
  }
}
