import { AccountInfo } from "@/model/account/account_info";
import { Account } from "@/model/values/account";
import { Date } from "@/model/values/date";
import { toCamel } from "@/service/convert_service";

export const accountInfoFromJson = (json) => {
  const { date, balance, totalDeposit, totalPayment, monthlyPayment } =
    toCamel(json);

  return AccountInfo.valueOf({
    date,
    balance: Account.valueOf(balance),
    totalDeposit: Account.valueOf(totalDeposit),
    totalPayment: Account.valueOf(totalPayment),
    monthlyPayment: monthlyPayment.map((v) => {
      const payment = toCamel(v);
      return {
        date: Date.valueOf(payment.month).toStringMonth,
        amountOfMoney: Account.valueOf(payment.amountOfMoney),
      };
    }),
  });
};
