const properties = {
  dataCount: 0,
  listItems: [],
};
export class FileDataList {
  static valueOf(items = {}) {
    return new FileDataList(items);
  }
  static empty() {
    return new FileDataList();
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }
}
