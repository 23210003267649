import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Date } from "@/model/values/date";
import { toSnake } from "@/service/convert_service";
import { ReceivedInvoicing } from "@/model/received_invoicing/received_invoicing";
import { Bank } from "../bank/bank";
import { Branch } from "../bank/branch";
import { accountType } from "@/const/file_upload";

const properties = {
  id: 0,
  clientSpecifiedId: "",
  paymentDate: Date.today(),
  amountOfMoney: AmountOfMoney.empty(),
  fee: AmountOfMoney.empty(),
  receivedInvoicing: ReceivedInvoicing.empty(),
  memo: "",
  fileName: "",
  type: "BT",
  bank: Bank.empty(),
  branch: Branch.empty(),
  accountType: accountType[0],
  accountNumber: "",
  recipientNameKana: "",
  fileType: "form",
};
// typescriptにしたい
export class Payment {
  static valueOf(items = properties) {
    return new Payment({ ...properties, ...items });
  }
  static empty() {
    return Payment.valueOf();
  }
  constructor(items = properties) {
    Object.assign(this, items);
  }

  toJson() {
    const {
      clientSpecifiedId,
      fileName,
      amountOfMoney,
      paymentDate,
      receivedInvoicing,
      memo,
      type,
      bank,
      branch,
      accountType,
      accountNumber,
      recipientNameKana,
      fee,
    } = this;

    const paymentDetail = {
      type,
      clientSpecifiedId,
      amountOfMoney: amountOfMoney.value,
      bankCode: bank.code,
      branchCode: branch.code,
      fee: fee.value,
      accountType: accountType.id,
      accountNumber,
      recipientNameKana,
    };

    const params = {
      amountOfMoney: amountOfMoney.value,
      paymentNumber: clientSpecifiedId,
      title: fileName,
      paymentDate: paymentDate.toParam(),
      memo,
      paymentDetail: toSnake(paymentDetail),
    };

    if (receivedInvoicing.isExist) {
      params.linkedInvoicingId = receivedInvoicing.id;
    }
    return toSnake(params);
  }

  updateBank(value, fee) {
    const properties = {
      ...this,
      ...{
        bank: value ?? Bank.empty(),
        branch: Branch.empty(),
        fee: AmountOfMoney.valueOf(fee),
      },
    };
    return Payment.valueOf(properties);
  }
  updateBranch(value) {
    const properties = { ...this, ...{ branch: value ?? Branch.empty() } };
    return Payment.valueOf(properties);
  }
  updateAccountType(value) {
    const properties = { ...this, ...{ accountType: value } };
    return Payment.valueOf(properties);
  }
  updateAccountNumber(value) {
    const properties = { ...this, ...{ accountNumber: value } };
    return Payment.valueOf(properties);
  }
  updateRecipientNameKana(value) {
    const properties = { ...this, ...{ recipientNameKana: value } };
    return Payment.valueOf(properties);
  }
  updatePaymentNumber(value) {
    const properties = { ...this, ...{ clientSpecifiedId: value } };
    return Payment.valueOf(properties);
  }
  updatePaymentDate(value) {
    const properties = { ...this, ...{ paymentDate: value } };
    return Payment.valueOf(properties);
  }
  updateBillingDate(value) {
    const properties = { ...this, ...{ billingDate: value } };
    return Payment.valueOf(properties);
  }
  updateTitle(value) {
    const properties = { ...this, ...{ fileName: value } };
    return Payment.valueOf(properties);
  }
  updateAmountOfMoney(value) {
    const amountOfMoney = this.amountOfMoney.updateValue(Number(value));
    return Payment.valueOf({ ...this, ...{ amountOfMoney } });
  }
  updateMemo(value) {
    const properties = { ...this, ...{ memo: value } };
    return Payment.valueOf(properties);
  }
  updateApprovalDeadline(value) {
    const properties = { ...this, ...{ approvalDeadline: value } };
    return Payment.valueOf(properties);
  }
}
