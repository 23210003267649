import Repository from "@/infrastructure/api";

const resource = "auth_token_payments";
export class ExternalRepository {
  checkTransactionPassword = async (payload) => {
    await Repository.post(`${resource}/check_transaction_password`, payload);
  };

  checkSmsPasscode = async (payload) => {
    const { data } = await Repository.post(
      `${resource}/check_sms_passcode`,
      payload,
    );
    return data["redirect_url"];
  };
}
