import {
  invoicingStatus,
  paymentStatus,
  receivedInvoicingStatus,
  status,
} from "@/const/status";
import { Applicant } from "@/model/role/applicant";
import { Approver } from "@/model/role/approver";
import { FirstApprover } from "@/model/role/first_approver";
import { SecondApprover } from "@/model/role/second_approver";
import {
  transitionFromApplyingByApplicant,
  transitionFromApplyingByApprover,
  transitionFromApplyingByFirstApprover,
  transitionFromFirstApprovalByFirstApprover,
  transitionFromFirstApprovalBySecondeApprover,
  transitionFromGetBackByApplicant,
  transitionFromPaymentReservationByApprover,
  transitionFromRemandByApplicant,
} from "@/const/status_transition";

const getStatusMap = (type, value) => {
  const s = receivedInvoicingStatus();
  if (s[value]) return s;

  if (type.isPayment) return paymentStatus();
  if (type.isInvoicing) return invoicingStatus();
  return {};
};

export const convertStatusName = (authInfo, type, value) => {
  const s = getStatusMap(type, value);
  const v = s[value] || status[value];

  if (!v) return "";

  return convertStatusOptionListName(authInfo, v);
};

const convertStatusOptionListName = (authInfo, list) => {
  if (authInfo) {
    const { role, numberOfApprovalStep } = authInfo;

    if (list.value === status.applying.value) {
      if (
        numberOfApprovalStep === 2 &&
        (role.isSecondApprover || role.isFirstApprover)
      ) {
        return list.name_for_multi_step_approver;
      }
      if (numberOfApprovalStep === 1 && role.isApprover) {
        return list.name_for_single_step_approver;
      }
      return list.name;
    }

    if (list.value === status.first_approval.value) {
      if (numberOfApprovalStep == 2 && role.isSecondApprover) {
        return list.name_for_second_approver;
      }
      return list.name;
    }
  }

  return list.name;
};

export const genStatusList = (authInfo, list) => {
  const target = { ...list };
  if (authInfo) {
    if (!authInfo.isUseWorkflow || authInfo.company.numberOfEmployees === 1) {
      delete target.applying;
      delete target.first_approval;
      delete target.get_back;
      delete target.remand;
      delete target.withdraw;
      delete target.rejected;
      delete target.approval;
    } else if (authInfo.numberOfApprovalStep === 1) {
      delete target.first_approval;
    }
  }

  const result = Object.keys(target).map((value) => {
    const name = convertStatusOptionListName(authInfo, target[value]);
    return { value, name };
  });

  return result;
};

const extractValue = (transitions) =>
  transitions.reduce(
    (obj, item) => ({ ...obj, ...{ [item.value]: true } }),
    {},
  );
export const generateStatusTransitionMap = (role, value, onlyPrimaryUser) => {
  let transitions = [];
  switch (value) {
    case status.applying.value:
      transitions = transitionFromApplyingByRole(role);
      break;
    case status.get_back.value:
      transitions = transitionFromGetBackByRole(role);
      break;
    case status.remand.value:
      transitions = transitionFromRemandByRole(role);
      break;
    case status.first_approval.value:
      transitions = transitionFromFirstApprovalByRole(role);
      break;
    case status.payment_reservation.value:
      transitions = transitionFromPaymentReservationByRole(role);
      break;
    case status.canceled.value:
      transitions = transitionFromCancelByRole(role, onlyPrimaryUser);
      break;
  }

  if (hasDeleteTransition(role, value)) {
    transitions.push(status.delete);
  }
  return extractValue(transitions);
};

// ホワイトリストを作る
const transitionFromApplyingByRole = (role) => {
  let result = [];
  role.roles.forEach((role) => {
    if (role instanceof Applicant)
      result.push(...transitionFromApplyingByApplicant);
    else if (role instanceof Approver)
      result.push(...transitionFromApplyingByApprover);
    else if (role instanceof FirstApprover)
      result.push(...transitionFromApplyingByFirstApprover);
    else if (role instanceof SecondApprover)
      result.push(...transitionFromFirstApprovalBySecondeApprover);
  });
  return result;
};

const transitionFromGetBackByRole = (role) => {
  let result = [];
  role.roles.forEach((role) => {
    if (role instanceof Applicant)
      result.push(...transitionFromGetBackByApplicant);
  });
  return result;
};

const transitionFromRemandByRole = (role) => {
  let result = [];
  role.roles.forEach((role) => {
    if (role instanceof Applicant)
      result.push(...transitionFromRemandByApplicant);
  });
  return result;
};

const transitionFromFirstApprovalByRole = (role) => {
  let result = [];
  role.roles.forEach((role) => {
    if (role instanceof SecondApprover)
      result.push(...transitionFromFirstApprovalBySecondeApprover);
    if (role instanceof FirstApprover)
      result.push(...transitionFromFirstApprovalByFirstApprover);
    if (role instanceof Applicant)
      result.push(...transitionFromApplyingByApplicant);
  });
  return result;
};

const transitionFromPaymentReservationByRole = (role) => {
  let result = [];
  role.roles.forEach((role) => {
    if (role instanceof SecondApprover || role instanceof Approver)
      result.push(...transitionFromPaymentReservationByApprover);
  });
  return result;
};

const transitionFromCancelByRole = (role, onlyPrimaryUser) => {
  if (onlyPrimaryUser) {
    return [status.delete, status.applying];
  }

  let result = [];
  role.roles.forEach((role) => {
    if (role instanceof SecondApprover)
      result.push(...transitionFromFirstApprovalBySecondeApprover);
    else if (role instanceof FirstApprover)
      result.push(...transitionFromFirstApprovalByFirstApprover);
    else if (role instanceof Approver)
      result.push(...transitionFromApplyingByApprover);
    else if (role instanceof Applicant)
      result.push(...transitionFromApplyingByApplicant);
  });
  return result;
};

const hasDeleteTransition = (role, value) => {
  const whitelist = [status.remand, status.withdraw, status.get_back];
  const values = whitelist.map((e) => e.value);
  return values.includes(value) && role.isApplicant;
};
