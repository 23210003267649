import { SmsAuthenticateCode } from "@/model/sms/sms_authenticate_code";
import { errorToMsg } from "@/service/convert_service";

export class SmsAuthenticateBehavior {
  constructor({ repository, loginRepository, codeList }) {
    this.repository = repository;
    this.loginRepository = loginRepository;
    this.codeList = codeList;
  }
  static create({ repository, loginRepository }) {
    const range = [...Array(6).keys()];
    const codeList = range.map((id) =>
      SmsAuthenticateCode.valueOf({ id, value: "" }),
    );
    return new SmsAuthenticateBehavior({
      repository,
      loginRepository,
      codeList,
    });
  }

  async clear() {
    this.codeList = [];
  }

  // (SmsAuthenticateCode) => void
  async updateCode(value) {
    const newList = this.codeList.filter((e) => e.id !== value.id);
    this.codeList = [...newList, value].sort((a, b) => a.id - b.id);
  }

  async previousDelete(index) {
    const newCode = SmsAuthenticateCode.valueOf({ id: index - 1, value: "" });
    this.updateCode(newCode);
  }

  async tryAuthenticate(phoneNumber) {
    try {
      const payload = {
        "phone-number": phoneNumber,
        passcode: this.codeList.join(""),
      };
      await this.repository.smsAuthenticate(payload);
    } catch (err) {
      throw errorToMsg(err);
    }
  }

  async tryAuthenticateWithUpdatePhoneNumber(phoneNumber, jwt) {
    try {
      const payload = {
        "phone-number": phoneNumber,
        passcode: this.codeList.join(""),
      };
      await this.repository.smsAuthenticateWithUpdatePhoneNumber(payload, {
        Authorization: `Bearer ${jwt}`,
      });
    } catch (err) {
      throw errorToMsg(err);
    }
  }

  async tryIssue(phoneNumber, header) {
    try {
      const payload = { "phone-number": phoneNumber };
      await this.repository.issueSmsAuthentication(payload, header);
    } catch (err) {
      throw errorToMsg(err);
    }
  }
}
