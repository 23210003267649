import Repository from "@/infrastructure/api";
import { toCamel } from "@/service/convert_service";

const resource = "service_bills";
export class ServiceBillRepository {
  fetchPaymentMethod = async () => {
    const { data } = await Repository.get(`${resource}/payment_method`);
    return toCamel(data);
  };

  updatePaymentMethod = async (payload) => {
    await Repository.put(`${resource}/payment_method`, payload);
  };

  fetchCardEditUrl = async () => {
    const { data } = await Repository.post(
      `${resource}/payment_method/create_credit_card_edit_url`,
    );
    return toCamel(data);
  };
}
