import { AuthInfo } from "@/model/auth_info/auth_info";

export default {
  namespaced: true,
  state: {
    jwt: "",
    smsAuthTokens: {},
    authInfo: AuthInfo.empty(),
    transactionPassword: "",
  },
  mutations: {
    updateJwt: (state, { jwt }) => (state.jwt = jwt),
    updateSmsAuthToken: (state, params) =>
      (state.smsAuthTokens = { ...state.smsAuthTokens, ...params }),
    updateAuthInfo: (state, payload) =>
      (state.authInfo = AuthInfo.valueOf({ ...payload, authenticated: true })),
    updateCellphoneNumber: (state, { phoneNumber }) =>
      (state.authInfo = state.authInfo.updateCellphoneNumber(phoneNumber)),
    updateTransactionPassword: (state, transactionPassword) =>
      (state.transactionPassword = transactionPassword),
    clearJwt: (state) => (state.jwt = ""),
    clearAuthInfo: (state) => (state.authInfo = AuthInfo.empty()),
    clearTransactionPassword: (state) => (state.transactionPassword = ""),
  },
  actions: {},
  getters: {
    jwt: (state) => state.jwt,
    smsAuthToken: (state) => (loginId) => state.smsAuthTokens[loginId],
    authInfo: (state) => state.authInfo,
    isUseWorkflow: (state) => state.authInfo.isUseWorkflow,
    onlyPrimaryUser: (state) => state.authInfo.company.numberOfEmployees === 1,
    role: (state) => state.authInfo.role,
    authenticated: (state) => state.authInfo.authenticated,
    companyName: (state) => state.authInfo.companyName,
    memberInfo: (state) => {
      return {
        companyName: state.authInfo.displayCompanyName,
        mainPhoneNumber: state.authInfo.mainPhoneNumber,
        registeredAt: state.authInfo.registeredAt,
      };
    },
    transactionPassword: (state) => state.transactionPassword,
  },
};
