const properties = {
  id: "",
  name: "",
  code: "",
};

export class Branch {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    return new Branch({ ...properties, ...item });
  }

  static empty() {
    return Branch.valueOf({});
  }

  toString() {
    return this.name;
  }
}
