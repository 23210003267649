import { bankFromJson } from "@/dto/bank";
import Repository from "@/infrastructure/api";

const resource = "/banks";
export class BankRepository {
  fetchBanks = async () => {
    const { data } = await Repository.get(`${resource}`);
    return data.map((v) => bankFromJson(v));
  };
}
