// import { applicationType } from "@/const/application_type";
import { functionPermissions } from "@/const/function_permissions";
import { displayFunctionPermissions } from "@/const/display_function_permissions";
import { operationTypeList } from "@/const/operation_type";
import {
  invoicingStatus,
  paymentStatus,
  paymentFileStatus,
  receivedInvoicingStatus,
  applicationStatusList,
} from "@/const/status";
import { rolesAll, rolesExcludeSecondeApprover } from "@/const/workflow_roles";
// import { ApplicationType } from "@/model/values/application_type";
import { genStatusList } from "@/service/status_service";

export default {
  namespaced: true,
  actions: {},
  getters: {
    paymentStatusList: () => (authInfo) =>
      genStatusList(authInfo, paymentStatus()),
    paymentFileStatusList: () => (authInfo) =>
      genStatusList(authInfo, paymentFileStatus()),
    invoicingStatusList: () => (authInfo) =>
      genStatusList(authInfo, invoicingStatus()),
    applicationStatusList: () => (authInfo) =>
      genStatusList(authInfo, applicationStatusList()),
    receivedInvoicingStatusList: () =>
      genStatusList(null, receivedInvoicingStatus()),
    // applicationTypeList: () =>
    //   Object.keys(applicationType).map((item) => ApplicationType.valueOf(item)),
    functionPermissions: () => functionPermissions,
    displayFunctionPermissions: () => displayFunctionPermissions,
    operationTypeList: () => operationTypeList,
    roles: () => (authInfo) =>
      authInfo.useSecondApprove ? rolesAll() : rolesExcludeSecondeApprover(),
  },
};
