import { PageNation } from "@/model/form/page_nation";
import { toSnake } from "@/service/convert_service";
import { DateRange } from "../values/date_range";

const properties = {
  pageNation: PageNation.firstPage(),
  employeeName: "",
  employeeId: { id: 0, name: "すべて" },
  operationType: "",
  operationDate: DateRange.empty(),
  sortDirection: "desc",
  sortColumn: "id",
};

export class WorkFlowSearchForm {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new WorkFlowSearchForm({ ...properties, ...items });
  }
  static empty() {
    return new WorkFlowSearchForm();
  }

  clear() {
    const { pageNation } = this;
    return WorkFlowSearchForm.valueOf({ ...properties, ...{ pageNation } });
  }

  updateEmployeeId(value) {
    return WorkFlowSearchForm.valueOf({ ...this, employeeId: value });
  }
  // 処理内容
  updateOperationType(value) {
    return WorkFlowSearchForm.valueOf({ ...this, operationType: value });
  }
  // 対象期間
  updateOperationDate(value) {
    const operationDate = this.operationDate.updateDateRange(value);
    return WorkFlowSearchForm.valueOf({ ...this, operationDate });
  }

  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return WorkFlowSearchForm.valueOf({ ...this, pageNation });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return WorkFlowSearchForm.valueOf({ ...this, pageNation });
  }

  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return WorkFlowSearchForm.valueOf({ ...this, pageNation });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return WorkFlowSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return WorkFlowSearchForm.valueOf({ ...this, ...{ sortColumn: value } });
  }

  toJson() {
    const {
      pageNation,
      operationDate,
      employeeId,
      operationType,
      sortColumn,
      sortDirection,
    } = this;
    const json = pageNation.toJson();
    if (employeeId.id) Object.assign(json, { employeeId: employeeId.id });
    if (operationType) Object.assign(json, { operationType });
    if (operationDate.isValid) {
      json.operationDateFrom = operationDate.startDate;
      json.operationDateTo = operationDate.endDate;
    }

    json.sort_direction = sortDirection;
    json.sort_column = sortColumn;

    return toSnake(json);
  }
}
