import { InvoicingDetail } from "@/model/invoicing/invoicing_detail";
import { InvoicingEdit } from "@/model/invoicing/invoicing_edit";
import { InvoicingListItem } from "@/model/invoicing/invoicing_list_item";
import { ServiceBill } from "@/model/service_bill/service_bill";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { ApplicationType } from "@/model/values/application_type";
import { Date } from "@/model/values/date";
import { Status } from "@/model/values/status";
import { toCamel } from "@/service/convert_service";
import {
  applicationDetailFromJson,
  applicationHistoryFromJson,
} from "../application";
import { Application } from "@/model/application/application";
import { BankAccount } from "@/model/bank/bank_account";

export const invoicingListItemFromJson = (json) => {
  const {
    invoicingId,
    invoicingNumber,
    billingDate,
    title,
    amountOfMoney,
    status,
    deadlineOn,
    memo,
  } = toCamel(json);

  const type = ApplicationType.invoicing();
  const application = Application.valueOf({
    type,
    status: Status.valueOf(status),
  });
  return InvoicingListItem.valueOf({
    id: invoicingId,
    billingDate: Date.valueOf(billingDate),
    amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
    status: Status.valueOf(status),
    deadlineOn: Date.valueOf(deadlineOn),
    application,
    invoicingNumber,
    title,
    memo,
  });
};

export const invoicingDetailFromJson = (json) => {
  const invoicing = toCamel(json["invoicing"]);
  const {
    invoicingNumber,
    billingDate,
    amountOfMoney,
    status,
    approvalDeadline,
    title,
    memo,
    deadlineOn,
  } = invoicing;

  const type = ApplicationType.invoicing();
  const newestApplication = json["application_history"][0];
  const application = applicationDetailFromJson(
    newestApplication,
    approvalDeadline,
    type,
  );
  const applicationHistory = json["application_history"].map((item) =>
    applicationHistoryFromJson(item, type),
  );
  return InvoicingDetail.valueOf({
    id: invoicing.invoicingId,
    invoicingNumber,
    billingDate: Date.valueOf(billingDate),
    amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
    status: Status.valueOf(status),
    deadlineOn: Date.valueOf(deadlineOn),
    title,
    memo,
    application,
    applicationHistory,
  });
};

export const invoicingEditFromJson = (json) => {
  const invoicing = toCamel(json["invoicing"]);
  const {
    invoicingNumber,
    billingDate,
    amountOfMoney,
    status,
    approvalDeadline,
    title,
    memo,
    deadlineOn,
  } = invoicing;

  const type = ApplicationType.invoicing();
  const newestApplication = json["application_history"][0];
  const application = applicationDetailFromJson(
    newestApplication,
    approvalDeadline,
    type,
  );
  return InvoicingEdit.valueOf({
    id: invoicing.invoicingId,
    invoicingNumber,
    billingDate: Date.valueOf(billingDate),
    amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
    status: Status.valueOf(status),
    deadlineOn: Date.valueOf(deadlineOn),
    title,
    memo,
    application,
  });
};

export const serviceBillFromJson = (json) => {
  const { targetMonth, paymentDeadline, billingAmount, mftMember } =
    toCamel(json);

  return ServiceBill.valueOf({
    targetMonth: Date.valueOf(targetMonth),
    paymentDeadline: Date.valueOf(paymentDeadline),
    billingAmount: AmountOfMoney.valueOf(billingAmount),
    bankAccountInformation: BankAccount.valueOf(
      toCamel(mftMember.bank_account_informations[0]),
    ),
  });
};
