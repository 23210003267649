import { Date } from "@/model/values/date";
import { WorkflowPermission } from "@/model/role/workflow_permission";
import { EmployeeStatus } from "@/model/employee/employee_status";

const properties = {
  id: 0,
  employeeNumber: "",
  lastName: "",
  firstName: "",
  lastNameKana: "",
  firstNameKana: "",
  birthday: Date.empty(),
  cellphoneNumber: "",
  email: "",
  workflowPermission: WorkflowPermission.empty(),
  status: EmployeeStatus.empty(),
};

export class EmployeeListItem {
  static valueOf(items = properties) {
    return new EmployeeListItem({ ...properties, ...items });
  }
  static empty() {
    return new EmployeeListItem();
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }

  get employeeFullName() {
    if (!this.lastName && !this.firstName) return "-";
    return this.lastName + this.firstName;
  }

  get displayEmployeeNumber() {
    return this.employeeNumber ? this.employeeNumber : "-";
  }

  get role() {
    return this.workflowPermission;
  }
}
