import { Date } from "@/model/values/date";

const properties = {
  id: 0,
  message: "",
  isImportant: false,
  type: "",
  postPeriodFrom: Date.empty(),
  postPeriodTo: Date.empty(),
  targetId: 0,
  target: "",
  isRead: false,
};

export class InformationListItem {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new InformationListItem({ ...properties, ...items });
  }
  static empty() {
    return InformationListItem.valueOf({});
  }

  get isUnRead() {
    return !this.isRead;
  }
}
