import { Date } from "@/model/values/date";
import { toCamel } from "@/service/convert_service";
import { Member } from "@/model/member/member";
import { accountType } from "@/const/file_upload";

export const companyFromJson = (json) => {
  const {
    memberId,
    companyName,
    displayCompanyName,
    zipCode,
    address1,
    address2,
    address3,
    corporatePhoneNumber,
    registerdAt, // プロパティ名はサーバー側のミス
    leavedAt,
    useStartAt,
    useStopAt,
    isUseWorkflow,
    numberOfApprovalStep,
    numberOfEmployees,
    isValidB2bCoinToken,
    status,
    privacySettings,
    biometricsSettings,
    defaultDeadlineDays,
    transferFeeSettings,
    bankAccountSettings,
    isMftMember,
    ganbStatus,
  } = toCamel(json);

  const bankSettings = toCamel(bankAccountSettings);

  return Member.valueOf({
    id: memberId,
    companyName,
    displayCompanyName,
    zipCode,
    address1,
    address2,
    address3,
    corporatePhoneNumber,
    registeredAt: Date.valueOf(registerdAt),
    leavedAt: Date.valueOf(leavedAt),
    useStartAt: Date.valueOf(useStartAt),
    useStopAt: Date.valueOf(useStopAt),
    isUseWorkflow,
    numberOfApprovalStep,
    numberOfEmployees,
    isValidB2bCoinToken,
    status,
    privacySettings: toCamel(privacySettings),
    biometricsSettings: toCamel(biometricsSettings),
    defaultDeadlineDays,
    transferFeeSettings: toCamel(transferFeeSettings),
    bankAccountSettings: {
      ...bankSettings,
      accountType: accountType.find(
        (type) => type.id === bankSettings.accountType,
      ),
    },
    isMftMember,
    ganbStatus,
  });
};
