import { operation } from "@/const/operation";

export class Operation {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value = "") {
    return new Operation(value);
  }
  static empty(value) {
    return Operation.valueOf(value);
  }

  static approval() {
    return Operation.valueOf(operation.approval.value);
  }
  static reject() {
    return Operation.valueOf(operation.reject.value);
  }
  static remand() {
    return Operation.valueOf(operation.remand.value);
  }
  static comment() {
    return Operation.valueOf(operation.comment.value);
  }
  static getBack() {
    return Operation.valueOf(operation.get_back.value);
  }
  static reapply() {
    return Operation.valueOf(operation.reapply.value);
  }
  static withdraw() {
    return Operation.valueOf(operation.withdraw.value);
  }
  static firstApproval() {
    return Operation.valueOf(operation.first_approval.value);
  }
  static secondApproval() {
    return Operation.valueOf(operation.second_approval.value);
  }
  static cancel() {
    return Operation.valueOf(operation.cancel.value);
  }

  toString() {
    const { value } = this;
    return operation[value] ? operation[value].name : value;
  }

  get style() {
    const { value } = this;
    return operation[value] ? operation[value].style : "";
  }
}
