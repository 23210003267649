import dayjs from "@/dayjs";
import { Date } from "@/model/values/date";
import { DateRange } from "@/model/values/date_range";

export const validate = (date) => {
  return dayjs(date.value).isValid();
};

export const checkTlToday = (date) => {
  return dayjs(date).startOf("day") < dayjs().startOf("day");
};

export const format = (date, format = "YYYY-MM-DD") => {
  return dayjs(date).local().format(format);
};

export const displayFormat = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const yearMonthFromToday = (to) => {
  const diff = dayjs().diff(to, "month");
  const range = [...Array(diff).keys()];
  const d = dayjs();
  return range.map((i) => {
    const clone = d.clone();
    return clone.subtract(i, "month").startOf("month");
  });
};

export const datePlaceholder = () => {
  return Date.today();
};

export const dateRangePlaceholder = () => {
  const [start, end] = [Date.today(), Date.today()];
  return DateRange.valueOf({ start, end });
};

export const isSameOrBefore = (date, target) => {
  return dayjs(date).isSameOrBefore(dayjs(target));
};
