import { Date } from "@/model/values/date";
import { toSnake } from "@/service/convert_service";
import { ApplicationType } from "@/model/values/application_type";
import { Status } from "@/model/values/status";
import { applicationCompleteModalMessages } from "@/service/message_service";
import { Employee } from "../employee/employee";

const properties = {
  id: 0,
  applicationDate: Date.empty(),
  status: Status.empty(),
  type: ApplicationType.empty(),
  approvalDeadline: Date.empty(),
  comment: "",
  operation: "",
  applicant: Employee?.empty(),
  lastOperationAt: Date.empty(),
};

export class Application {
  constructor(item = properties) {
    Object.assign(this, item);
  }

  static valueOf(item = properties) {
    return new Application({ ...properties, ...item });
  }

  static empty() {
    return Application.valueOf();
  }

  static init(defaultDeadlineDays) {
    return Application.valueOf({
      ...properties,
      ...{ approvalDeadline: Date.todayAfter(defaultDeadlineDays) },
    });
  }

  updateApprovalDeadline(value) {
    return Application.valueOf({
      ...this,
      ...{ approvalDeadline: Date.valueOf(value) },
    });
  }
  updateComment(value) {
    return Application.valueOf({ ...this, ...{ comment: value } });
  }
  updateApplicationCondition(value) {
    return Application.valueOf({ ...this, ...{ applicationCondition: value } });
  }

  // 使って無さそう
  toJson() {
    const { approvalDeadline, comment, lastOperationAt } = this;
    const applicationDate = Date.today();
    const json = {
      applicationDate: applicationDate.toParam(),
      approvalDeadline: approvalDeadline.toParam(),
      comment,
      lastOperationAt: lastOperationAt.toParamISO(),
    };

    return toSnake(json);
  }

  get modalMessageMap() {
    return applicationCompleteModalMessages(this.type);
  }

  get completeModalTitle() {
    const { status, modalMessageMap } = this;
    const result = modalMessageMap[status.value];
    return result ? result.title : "";
  }

  get completeModalMessage() {
    const { status, modalMessageMap } = this;
    const result = modalMessageMap[status.value];
    return result ? result.message : "";
  }

  get isExist() {
    return this.id;
  }
}
