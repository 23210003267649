import { Bank } from "@/model/bank/bank";
import { Branch } from "@/model/bank/branch";
import { toCamel } from "@/service/convert_service";

export const bankFromJson = (json) => {
  const detail = toCamel(json);
  const branchList = detail.branchList.map((branch) => {
    return Branch.valueOf({
      ...branch,
      id: branch.code,
    });
  });
  return Bank.valueOf({
    ...detail,
    id: detail.code,
    branchList,
  });
};
