import { notificationListFromJson } from "@/dto/notification";
import Repository from "@/infrastructure/api";

const resource = "notifications";
export class NotificationRepository {
  fetchList = async (params, config = {}) => {
    const { data } = await Repository.get(`${resource}`, {
      ...{ params },
      ...config,
    });
    return notificationListFromJson(data);
  };
}
