import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Date } from "@/model/values/date";
import { Status } from "@/model/values/status";

const properties = {
  id: 0,
  invoicingNumber: "",
  billingDate: Date.empty(),
  deadlineOn: Date.empty(),
  title: "",
  amountOfMoney: AmountOfMoney.empty(),
  status: Status.empty(),
  memo: "",
};

export class ReceivedInvoicing {
  static valueOf(items = properties) {
    return new ReceivedInvoicing(items);
  }
  static empty() {
    return new ReceivedInvoicing();
  }
  // TODO: モデリング精査
  constructor(items = properties) {
    Object.assign(this, items);
  }

  toJson() {
    return JSON.stringify(this);
  }

  get isExist() {
    return this.id;
  }

  get isConverted() {
    if (this.status.isEmpty) return false;
    return this.status.isConverted;
  }
}
