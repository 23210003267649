import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Date } from "@/model/values/date";
import { ReceivedInvoicing } from "@/model/received_invoicing/received_invoicing";
import { toSnake } from "@/service/convert_service";
import { Bank } from "../bank/bank";
import { Branch } from "../bank/branch";
import { accountType } from "@/const/file_upload";
import { Application } from "../application/application";

const properties = {
  id: 0,
  fileId: 0,
  clientSpecifiedId: "",
  fileName: "",
  amountOfMoney: AmountOfMoney.empty(),
  fee: AmountOfMoney.empty(),
  paymentDate: Date.empty(),
  memo: "",
  receivedInvoicing: ReceivedInvoicing.empty(),
  application: Application.empty(),
  type: "BT",
  bank: Bank.empty(),
  branch: Branch.empty(),
  accountType: accountType[0],
  accountNumber: "",
  recipientNameKana: "",
  isServiceBill: false,
};
// typescriptにしたい
export class PaymentEdit {
  static valueOf(items = properties) {
    return new PaymentEdit({ ...properties, ...items });
  }
  static empty() {
    return PaymentEdit.valueOf();
  }
  constructor(items = properties) {
    Object.assign(this, items);
  }

  toJson() {
    const {
      id,
      clientSpecifiedId,
      fileName,
      amountOfMoney,
      paymentDate,
      memo,
      application,
      type,
      bank,
      branch,
      accountType,
      accountNumber,
      recipientNameKana,
      fee,
    } = this;

    const paymentDetail = {
      lineNumber: 1,
      type,
      clientSpecifiedId,
      amountOfMoney: Number(amountOfMoney.value),
      bankCode: bank.code,
      branchCode: branch.code,
      fee: fee.value,
      accountType: accountType.id,
      accountNumber: accountNumber,
      recipientNameKana,
    };

    const params = {
      paymentId: id,
      paymentNumber: clientSpecifiedId,
      title: fileName,
      amountOfMoney: amountOfMoney.value,
      paymentDate: paymentDate.toParam(),
      memo,
    };

    if (application.isExist) {
      Object.assign(params, {
        application: {
          approval_deadline: application.approvalDeadline.toParam(),
        },
      });
    }

    return { payment: toSnake(params), paymentDetail: toSnake(paymentDetail) };
  }

  updateBank(value, fee) {
    const properties = {
      ...this,
      ...{
        bank: value ?? Bank.empty(),
        branch: Branch.empty(),
        fee: AmountOfMoney.valueOf(fee),
      },
    };
    return PaymentEdit.valueOf(properties);
  }
  updateBranch(value) {
    const properties = { ...this, ...{ branch: value ?? Branch.empty() } };
    return PaymentEdit.valueOf(properties);
  }
  updateAccountType(value) {
    const properties = { ...this, ...{ accountType: value } };
    return PaymentEdit.valueOf(properties);
  }
  updateAccountNumber(value) {
    const properties = { ...this, ...{ accountNumber: value } };
    return PaymentEdit.valueOf(properties);
  }
  updateRecipientNameKana(value) {
    const properties = { ...this, ...{ recipientNameKana: value } };
    return PaymentEdit.valueOf(properties);
  }
  updatePaymentNumber(value) {
    const properties = { ...this, ...{ clientSpecifiedId: value } };
    return PaymentEdit.valueOf(properties);
  }
  updatePaymentDate(value) {
    const properties = { ...this, ...{ paymentDate: value } };
    return PaymentEdit.valueOf(properties);
  }
  updateTitle(value) {
    const properties = { ...this, ...{ fileName: value } };
    return PaymentEdit.valueOf(properties);
  }
  updateAmountOfMoney(value) {
    const amountOfMoney = this.amountOfMoney.updateValue(Number(value));
    return PaymentEdit.valueOf({ ...this, ...{ amountOfMoney } });
  }
  updateMemo(value) {
    const properties = { ...this, ...{ memo: value } };
    return PaymentEdit.valueOf(properties);
  }
  updateApprovalDeadline(value) {
    const application = this.application.updateApprovalDeadline(value);
    return PaymentEdit.valueOf({ ...this, application });
  }
}
