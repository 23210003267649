import * as target from "@/const/information_target";

const properties = {
  dataCount: 0,
  listItems: [],
};

export class InformationList {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new InformationList({ ...properties, ...items });
  }
  static empty() {
    return InformationList.valueOf({});
  }

  updateInformation(information) {
    const listItems = this._cloneInformation(information);
    return InformationList.valueOf({ ...this, listItems });
  }

  _cloneInformation(information) {
    const index = this.listItems.findIndex((e) => e.id === information.id);
    const clone = [...this.listItems];
    clone[index] = information;
    return clone;
  }

  // get isUnReadAnnounce() {
  //   return this.announce.some((e) => e.isUnRead);
  // }

  // get isUnReadPushNotification() {
  //   return this.pushNotification.some((e) => e.isUnRead);
  // }

  get invoicingNotificationList() {
    return this.pushNotification.filter((e) => e.target === target.invoicings);
  }
}
