import { OAuthClient } from "@/model/oauth/client";
import { toCamel } from "@/service/convert_service";

export const clientFromJson = (json) => {
  const { redirect, data } = toCamel(json);

  return OAuthClient.valueOf({
    redirect,
    data,
  });
};
