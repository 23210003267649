import { DateTime } from "../values/date_time";

const properties = {
  id: 0,
  operationNumber: "",
  employeeId: "",
  employeeName: "",
  operationDatetime: DateTime.empty(),
  operationType: "",
  operationDetail: "",
  operationDetailRemarks: "",
};

export class OperationLogListItem {
  constructor(items = properties) {
    Object.assign(this, items);
  }
  static valueOf(item = properties) {
    return new OperationLogListItem({ ...properties, ...item });
  }
  static empty() {
    return OperationLogListItem.valueOf({});
  }

  get displayOperationDetailRemarks() {
    const { operationDetailRemarks } = this;
    if (!operationDetailRemarks) return "";
    return `：${operationDetailRemarks}`;
  }
}
