import { Date } from "@/model/values/date";
import { toCamel } from "@/service/convert_service";
import { FileDataListItem } from "@/model/file_data/file_data_list_item";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Status } from "@/model/values/status";
import { FileDataDetail } from "@/model/file_data/file_data_detail";
import { Bank } from "@/model/bank/bank";
import { Branch } from "@/model/bank/branch";
import {
  applicationDetailFromJson,
  applicationHistoryFromJson,
} from "../application";
import { ApplicationType } from "@/model/values/application_type";
import { FilePaymentDetail } from "@/model/file_data/file_payment_detail";
import { accountType as accountTypeList } from "@/const/file_upload";
import { PaymentEdit } from "@/model/payment/payment_edit";
import { FileDataEdit } from "@/model/file_data/file_data_edit";
import { PaymentDetailStatus } from "@/model/values/payment_detail_status";

export const fileListItemFromJson = (json) => {
  const {
    fileId,
    fileUploadId,
    paymentId,
    fileName,
    uploadDate,
    status,
    paymentDate,
    amountOfMoney,
    fee,
    recordCount,
    recipientName,
    clientSpecifiedId,
    fileType,
    bankCode,
    branchCode,
    accountNumber,
    accountType,
    errorReason,
  } = toCamel(json);

  return FileDataListItem.valueOf({
    id: fileId,
    uploadId: fileUploadId,
    paymentId,
    paymentDate: Date.valueOf(paymentDate),
    uploadDate: Date.valueOf(uploadDate),
    recipientName,
    fileName,
    recordCount,
    amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
    fee: AmountOfMoney.valueOf(fee),
    status: Status.valueOf(status),
    clientSpecifiedId,
    fileType,
    bankCode,
    branchCode,
    accountNumber,
    accountType: accountTypeList.find((type) => type.id === accountType),
    errorReason,
  });
};

export const fileDetailFromJson = (json) => {
  const paymentDetails = json["payment_details"].map((detail) => {
    const {
      lineNumber,
      type,
      amountOfMoney,
      fee,
      accountType,
      accountNumber,
      bankCode,
      branchCode,
      clientSpecifiedId,
      recipientName1,
      recipientNameKana,
      status,
      errorReason,
    } = toCamel(detail);
    return FilePaymentDetail.valueOf({
      lineNumber,
      type,
      amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
      fee: AmountOfMoney.valueOf(fee),
      status: PaymentDetailStatus.valueOf(status),
      accountType: accountTypeList.find((type) => type.id === accountType),
      accountNumber,
      bank: Bank.valueOf({ code: bankCode }),
      branch: Branch.valueOf({ code: branchCode }),
      clientSpecifiedId,
      recipientName1,
      recipientNameKana,
      errorReason,
    });
  });
  const {
    paymentId,
    fileId,
    fileUploadId,
    fileName,
    fileType,
    uploadDate,
    status,
    paymentDate,
    amountOfMoney,
    fee,
    applicationDate,
    approvalDeadline,
    recordCount,
    recipientName,
    clientSpecifiedId,
    bankCode,
    branchCode,
    accountType,
    accountNumber,
    paymentMemo,
    errorReason,
    transferKind,
    lastOperationAt,
  } = toCamel(json["payment"]);

  const type = ApplicationType.payment();
  const newestApplication = json["application_history"][0];
  const application = applicationDetailFromJson(
    newestApplication,
    approvalDeadline,
    type,
    lastOperationAt,
  );
  const applicationHistory = json["application_history"].map((item) =>
    applicationHistoryFromJson(item, type),
  );

  return FileDataDetail.valueOf({
    paymentId,
    fileId,
    uploadId: fileUploadId,
    paymentDate: Date.valueOf(paymentDate),
    uploadDate: Date.valueOf(uploadDate),
    recipientName,
    fileName,
    fileType,
    recordCount,
    amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
    fee: AmountOfMoney.valueOf(fee),
    status: Status.valueOf(status),
    applicationDate: Date.valueOf(applicationDate),
    approvalDeadline: Date.valueOf(approvalDeadline),
    clientSpecifiedId,
    bank: Bank.valueOf({ code: bankCode }),
    branch: Branch.valueOf({ code: branchCode }),
    accountType: accountTypeList.find((type) => type.id === accountType),
    accountNumber,
    paymentDetails,
    application,
    applicationHistory,
    memo: paymentMemo,
    errorReason,
    transferKind,
  });
};

export const fileDetailEditFromJson = (json) => {
  const paymentDetails = json["payment_details"].map((detail) => {
    const {
      lineNumber,
      type,
      amountOfMoney,
      fee,
      accountType,
      accountNumber,
      bankCode,
      branchCode,
      clientSpecifiedId,
      recipientName1,
      recipientNameKana,
      status,
    } = toCamel(detail);
    return FilePaymentDetail.valueOf({
      lineNumber,
      type,
      amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
      fee: AmountOfMoney.valueOf(fee),
      status: PaymentDetailStatus.valueOf(status),
      accountType:
        accountTypeList.find((type) => type.id === accountType) ||
        accountTypeList[0],
      accountNumber,
      bank: Bank.valueOf({ code: bankCode }),
      branch: Branch.valueOf({ code: branchCode }),
      clientSpecifiedId,
      recipientName1,
      recipientNameKana,
    });
  });

  const {
    paymentId,
    fileId,
    paymentDate,
    approvalDeadline,
    fileName,
    paymentMemo,
    amountOfMoney,
    fee,
    status,
    fileType,
  } = toCamel(json["payment"]);

  const type = ApplicationType.payment();
  const newestApplication = json["application_history"][0];
  const application = applicationDetailFromJson(
    newestApplication,
    approvalDeadline,
    type,
  );

  return FileDataEdit.valueOf({
    paymentId,
    fileId,
    fileName,
    amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
    fee: AmountOfMoney.valueOf(fee),
    application,
    paymentDate: Date.valueOf(paymentDate),
    memo: paymentMemo,
    paymentDetails,
    status: Status.valueOf(status),
    fileType,
  });
};

export const paymentEditFromJson = (json) => {
  const {
    paymentId,
    fileId,
    paymentDate,
    approvalDeadline,
    fileName,
    paymentMemo,
  } = toCamel(json["payment"]);

  const {
    type,
    amountOfMoney,
    fee,
    accountType,
    accountNumber,
    bankCode,
    branchCode,
    clientSpecifiedId,
    recipientNameKana,
  } = toCamel(json["payment_details"][0]);

  const newestApplication = json["application_history"][0];
  const application = applicationDetailFromJson(
    newestApplication,
    approvalDeadline,
    ApplicationType.payment(),
  );

  return PaymentEdit.valueOf({
    id: paymentId,
    fileId,
    clientSpecifiedId,
    amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
    fee: AmountOfMoney.valueOf(fee),
    paymentDate: Date.valueOf(paymentDate),
    application,
    type,
    bank: Bank.valueOf({ code: bankCode }),
    branch: Branch.valueOf({ code: branchCode }),
    accountType:
      accountTypeList.find((type) => type.id === accountType) ||
      accountTypeList[0],
    accountNumber,
    recipientNameKana,
    fileName,
    memo: paymentMemo,
    // TODO: 利用料金支払の判定方法検討
    isServiceBill: bankCode === "0310" && fileName.includes("利用料金"),
  });
};
