import { toSnake } from "@/service/convert_service";

// 奇数であること
const num = 5;
export class PageNation {
  constructor({ numberOfData, page, dataCount }) {
    this.numberOfData = numberOfData;
    this.page = page;
    this.dataCount = dataCount;
  }

  static valueOf({ numberOfData, page = 1, dataCount = 0 }) {
    return new PageNation({ numberOfData, page, dataCount });
  }
  static empty() {
    return new PageNation({ numberOfData: 0, page: 0 });
  }
  static firstPage() {
    return new PageNation({ numberOfData: 10, page: 1 });
  }
  get isFirstPage() {
    return this.page === 1;
  }

  updateNumberOfData(value) {
    this.numberOfData = Number(value);
    return PageNation.valueOf({
      ...this,
      page: 1,
      numberOfData: Number(value),
    });
  }

  updatePage(value) {
    return PageNation.valueOf({ ...this, page: Number(value) });
  }

  get from() {
    const { dataCount, page, numberOfData } = this;
    if (!dataCount) return 0;
    const total = numberOfData * (page - 1);
    return total + 1;
  }

  get to() {
    const { dataCount, page, numberOfData } = this;
    if (dataCount < numberOfData * page) {
      return dataCount;
    }
    return numberOfData * page;
  }

  get pages() {
    const { dataCount, numberOfData } = this;
    if (!dataCount) return [];

    const div = Math.trunc(dataCount / numberOfData);
    const mod = Math.trunc(dataCount % numberOfData);
    if (mod) {
      return [...Array(div + 1).keys()].map((e) => e + 1);
    }
    return [...Array(div).keys()].map((e) => e + 1);
  }

  get pagesOmittedMiddle() {
    const { page, pages } = this;
    const current = page - 1;
    const offset = Math.trunc(num / 2);
    const pos = Math.max(0, current - offset);
    let omitted = pages.slice(pos, pos + num); // TODO: 別の計算方法に変更する
    if (omitted.length < num) {
      omitted = pages.slice(num * -1);
    }
    return omitted.map((e) => String(e));
  }

  get displayRecordCount() {
    return this.numberOfData * this.page;
  }

  get isLastPage() {
    return this.dataCount - this.displayRecordCount > 0;
  }

  updateDataCount(value) {
    return PageNation.valueOf({ ...this, ...{ dataCount: value } });
  }
  // TODO: リクエストパラメータへの変換はtoJsonで統一する
  toJson() {
    const params = {
      numberOfData: this.numberOfData,
    };
    params.page = (this.page - 1) * this.numberOfData;
    return toSnake(params);
  }
}
