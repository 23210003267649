const properties = {
  dataCount: 0,
  listItems: [],
};

export class CashflowList {
  constructor({ dataCount, listItems }) {
    this.dataCount = dataCount;
    this.listItems = listItems;
  }

  static valueOf({ dataCount, listItems }) {
    return new CashflowList({ dataCount, listItems });
  }
  static empty() {
    return CashflowList.valueOf(properties);
  }
}
