import { Date } from "@/model/values/date";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Status } from "@/model/values/status";

const properties = {
  targetId: 0,
  fileId: 0,
  approvalDeadline: Date.empty(),
  status: Status.empty(),
  amountOfMoney: AmountOfMoney.empty(),
  fee: AmountOfMoney.empty(),
  fileName: "",
  fileType: "",
  recipientName: "",
  recordCount: 0,
  paymentId: 0,
  clientSpecifiedId: "",
  applicantName: "",
  errorReason: "",
};

export class ApplicationListItem {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    return new ApplicationListItem(item);
  }

  static empty() {
    return ApplicationListItem.valueOf({});
  }

  get applicationId() {
    if (!this.id) return "";
    return String(this.id).padStart(7, "0");
  }

  get isExpired() {
    return this.approvalDeadline.ltToday;
  }
}
