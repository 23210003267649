import store from "@/store";

const storageKey = "token";

export const authenticate = () => {
  const { jwt } = store.state.auth;
  if (jwt) return true;

  return isExistJwt();
};

export const authSetUp = (tokenOnStore) => {
  if (tokenOnStore) return { jwt: tokenOnStore };

  return loadJwt();
};

const isExistJwt = () => localStorage.getItem(storageKey);

const loadJwt = () => {
  const jwt = localStorage.getItem(storageKey);
  if (!jwt) return "";

  return JSON.parse(jwt);
};

export const saveJwt = (data) =>
  localStorage.setItem(storageKey, JSON.stringify(data));

export const clearJwt = () => localStorage.setItem(storageKey, "");

export const hasAuthInfo = ({ authenticated }) => authenticated;
