import { ApplicationListItem } from "@/model/application/application_list_item";
import { ApplicationType } from "@/model/values/application_type";
import { Date } from "@/model/values/date";
import { toCamel } from "@/service/convert_service";
import { Status } from "@/model/values/status";
import { ApplicationHistoryItem } from "@/model/application/application_history_item";
import { DateTime } from "@/model/values/date_time";
import { OperationEmployee } from "@/model/employee/operation_employee";
import { Operation } from "@/model/values/operation";
import { Employee } from "@/model/employee/employee";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { employeeFromJson } from "@/dto/employee";
import { Application } from "@/model/application/application";

export const applicationFromJson = (json) => {
  const { applicationId, type, approvalDeadline, title } = toCamel(
    json["application_detail"],
  );
  return ApplicationListItem.valueOf({
    id: applicationId,
    type: ApplicationType.valueOf(type),
    approvalDeadline: Date.valueOf(approvalDeadline),
    fileName: title,
  });
};

export const applicationListItemFromJson = (json) => {
  const {
    fileId,
    targetId,
    fileType,
    fileName,
    status,
    approvalDeadline,
    amountOfMoney,
    fee,
    recipientName,
    recordCount,
    paymentId,
    clientSpecifiedId,
    applicantName,
    errorReason,
  } = toCamel(json);
  return ApplicationListItem.valueOf({
    fileId,
    targetId,
    fileType,
    fileName,
    status: Status.valueOf(status),
    approvalDeadline: Date.valueOf(approvalDeadline),
    amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
    fee: AmountOfMoney.valueOf(fee),
    recipientName,
    recordCount,
    paymentId,
    clientSpecifiedId,
    applicantName,
    errorReason,
  });
};

export const applicationDetailFromJson = (
  json,
  approvalDeadline,
  type,
  lastOperationAt = null,
) => {
  if (!json) return Application.empty();
  const { applicationId, applicationDate, applicant, status, operation } =
    toCamel(json);
  const employee = toCamel(applicant);
  return Application.valueOf({
    id: applicationId,
    applicationDate: Date.valueOf(applicationDate),
    approvalDeadline: Date.valueOf(approvalDeadline),
    type,
    status: Status.valueOf(status),
    applicant: Employee.valueOf(employee),
    operation,
    lastOperationAt: Date.valueOf(lastOperationAt),
  });
};

export const applicationHistoryFromJson = (json, type) => {
  const {
    applicationId,
    operation,
    operationAt,
    status,
    applicant,
    approvalDeadline,
    applicationDate,
    comment,
  } = toCamel(json);
  const { lastName, firstName } = toCamel(applicant);
  return ApplicationHistoryItem.valueOf({
    id: applicationId,
    operation: Operation.valueOf(operation),
    operationAt: DateTime.valueOf(operationAt),
    operationEmployee: OperationEmployee.valueOf({ lastName, firstName }),
    type,
    comment,
    applicant: employeeFromJson(applicant),
    applicationDate: Date.valueOf(applicationDate),
    approvalDeadline: Date.valueOf(approvalDeadline),
    status: Status.valueOf(status),
  });
};
