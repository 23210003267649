import { CashflowListItem } from "@/model/balance/cashflow_list_item";
import { Balance } from "@/model/values/balance";
import { Date } from "@/model/values/date";
import { toCamel } from "@/service/convert_service";
import { cashFlowType } from "@/const/cash_flow";

export const cashFlowListItemFromJson = (json) => {
  const {
    transactionAt,
    toMemberName,
    amount,
    prefix,
    balance,
    fee,
    fileId,
    type,
    recordCount,
    fileType,
    paymentMemo,
  } = toCamel(json);

  return CashflowListItem.valueOf({
    transactionAt: Date.valueOf(transactionAt),
    prefix,
    toMemberName,
    amount: Balance.valueOf(amount),
    balance: Balance.valueOf(balance),
    fee: Balance.valueOf(fee),
    fileId,
    type: cashFlowType[type],
    recordCount,
    fileType,
    paymentMemo,
  });
};
