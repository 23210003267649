export class OAuthClient {
  static valueOf(item = {}) {
    return new OAuthClient(item);
  }
  static empty() {
    return new OAuthClient({});
  }
  constructor({ redirect, data }) {
    this.redirect = redirect;
    this.data = data;
  }

  toJson() {
    return JSON.stringify(this);
  }
}
