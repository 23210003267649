import { PageNation } from "@/model/form/page_nation";
import { toSnake } from "@/service/convert_service";

const properties = {
  pageNation: PageNation.firstPage(),
  sortDirection: "desc",
  sortColumn: "id",
};

export class EmployeeDeleteSearchForm {
  constructor({ pageNation, sortColumn, sortDirection }) {
    this.pageNation = pageNation;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static valueOf(items = properties) {
    return new EmployeeDeleteSearchForm({ ...properties, ...items });
  }

  static empty() {
    return EmployeeDeleteSearchForm.valueOf(properties);
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return EmployeeDeleteSearchForm.valueOf({
      ...this,
      ...{ pageNation },
    });
  }

  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return EmployeeDeleteSearchForm.valueOf({
      ...this,
      ...{ pageNation },
    });
  }
  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return EmployeeDeleteSearchForm.valueOf({
      ...this,
      ...{ pageNation },
    });
  }
  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return EmployeeDeleteSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return EmployeeDeleteSearchForm.valueOf({
      ...this,
      ...{ sortColumn: value },
    });
  }

  toJson() {
    const { pageNation, sortColumn, sortDirection } = this;
    const params = pageNation.toJson();

    params.sort_direction = sortDirection;
    params.sort_column = sortColumn;

    return toSnake(params);
  }
}
