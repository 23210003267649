import { Role } from "@/model/values/role";
export class WorkflowPermission {
  constructor({ roles = [] }) {
    this.roles = roles;
  }

  static valueOf({ roles = [] }) {
    return new WorkflowPermission({ roles });
  }

  static empty() {
    return WorkflowPermission.valueOf({});
  }

  get isApplicant() {
    return this.roles.find((e) => e.isApplicant);
  }
  get isApprover() {
    return this.roles.find((e) => e.isApprover);
  }
  get isFirstApprover() {
    return this.roles.find((e) => e.isFirstApprover);
  }
  get isSecondApprover() {
    return this.roles.find((e) => e.isSecondApprover);
  }
  get isAllApprover() {
    return this.roles.find(
      (e) => e.isApprover || e.isFirstApprover || e.isSecondApprover,
    );
  }

  updateValue(value) {
    const newRoles = this._newRoles(value);
    const roles = newRoles.map((e) => Role.valueOf(e));
    return WorkflowPermission.valueOf({ roles });
  }

  _newRoles(value) {
    const values = this.roles.map((e) => e.value);
    if (values.includes(value)) {
      return values.filter((e) => e !== value);
    }
    return [...values, value];
  }

  includes(value) {
    return this.roles.find((role) => role.value === value);
  }

  toString() {
    const { roles } = this;
    if (!roles.length) return "-";
    return roles.map((e) => e.toString()).join("、");
  }

  toJson() {
    return {
      workflowPermission: this.roles.map((e) => e.value),
    };
  }
}
