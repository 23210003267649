import dayjs from "@/dayjs";
import { format } from "@/service/date_service";

export class Date {
  constructor(value) {
    this.value = value;
  }

  static empty() {
    return Date.valueOf(null);
  }

  static valueOf(value) {
    return new Date(value ? dayjs(value) : null);
  }

  static today() {
    return new Date(dayjs());
  }

  static todayAfter(value) {
    return new Date(dayjs().add(value, "day"));
  }

  static todayBefore(value) {
    return new Date(dayjs().subtract(value, "day"));
  }

  static copyWith({ value }) {
    return Date.valueOf(value);
  }

  toString() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY/MM/DD");
  }

  toParam() {
    if (!this.isExist) return null;
    return format(this.value, "YYYY-MM-DD");
  }

  toParamISO() {
    if (!this.isExist) return null;
    return this.value.toISOString();
  }

  updateDate(value) {
    return Date.valueOf(value);
  }

  get isExist() {
    return this.value?.isValid();
  }

  get isToday() {
    return this.value.isToday();
  }

  get gtToday() {
    return dayjs().startOf("day") < this.value;
  }

  get ltToday() {
    return dayjs().startOf("day") > this.value;
  }

  get toStringYearAndMonth() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "MM/DD");
  }
  get toStringMonth() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "M");
  }

  get toStringWithTime() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY/MM/DD hh:mm:ss");
  }

  get toStringWithMinute() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY/MM/DD hh:mm");
  }

  format(_format) {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, _format);
  }
}
