// 残高
export class Account {
  constructor(value) {
    this.value = value;
  }

  static empty() {
    return Account.valueOf(0);
  }
  static valueOf(value = 0) {
    return new Account(value);
  }

  toString() {
    return `¥${this.value.toLocaleString()}`;
  }

  add(obj) {
    return Account.valueOf(this.value + obj.value);
  }
}
