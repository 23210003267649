import { AuthInfoBehavior } from "@/controller/auth_info/auth_info_behavior";
import { authSetUp } from "@/service/auth_service";
import { handleRequest } from "@/service/handle_service";
import store from "@/store";
import { setupRepository } from ".";
import { tryPermissionGuard } from "./guard";

const setup = async () => {
  const interact = AuthInfoBehavior.create({
    repository: setupRepository.auth,
  });
  const authInfoOnStore = store.state.auth.authInfo;
  const callback = () => {
    store.commit("auth/updateAuthInfo", interact.authInfo);
  };
  const { auth } = store.state;
  const jwt = authSetUp(auth.jwt);

  if (store.getters["auth/authenticated"]) return;

  store.commit("auth/updateJwt", jwt);
  await handleRequest(() => interact.initialize(authInfoOnStore), callback);
};

export const authFlow = async (to, from, next) => {
  try {
    await setup();
    tryPermissionGuard(to, from);
  } catch (err) {
    alert(err);
    if (from.path === "/") next(from);
    return;
  }
  next();
};
