export class GanbNotActivatedError extends Error {
  constructor(...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GanbNotActivatedError);
    }

    this.name = this.constructor.name;
  }

  toString() {
    return "GMOあおぞらネット銀行と連携されていません";
  }
}
