import { PageNation } from "@/model/form/page_nation";

const properties = {
  keyword: "",
  pageNation: PageNation.firstPage(),
  sortDirection: "asc",
  sortColumn: "lineNumber",
};

export class FileDataUploadConfirmSearchForm {
  constructor({ keyword, pageNation, sortColumn, sortDirection }) {
    this.keyword = keyword;
    this.pageNation = pageNation;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static valueOf(items = {}) {
    return new FileDataUploadConfirmSearchForm({ ...properties, ...items });
  }

  static empty() {
    return FileDataUploadConfirmSearchForm.valueOf(properties);
  }

  clear() {
    const { pageNation } = this;
    return FileDataUploadConfirmSearchForm.valueOf({
      ...properties,
      ...{ pageNation },
    });
  }

  updateKeyword(value) {
    return FileDataUploadConfirmSearchForm.valueOf({
      ...this,
      ...{ keyword: value },
    });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return FileDataUploadConfirmSearchForm.valueOf({
      ...this,
      ...{ pageNation },
    });
  }
  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return FileDataUploadConfirmSearchForm.valueOf({
      ...this,
      ...{ pageNation },
    });
  }

  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return FileDataUploadConfirmSearchForm.valueOf({
      ...this,
      ...{ pageNation },
    });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return FileDataUploadConfirmSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return FileDataUploadConfirmSearchForm.valueOf({
      ...this,
      ...{ sortColumn: value },
    });
  }
}
