import { Date } from "@/model/values/date";

export const isMatch = (a, b) => {
  return a.value === b.value;
};

export const isExistCheck = (value) => {
  if (value instanceof Date) return value.isExist;
  return value;
};
