import { PermissionError } from "@/error/permission_error";
import {
  isUseWorkflowGuard,
  readPermissionGuard,
  registerPermissionGuard,
  registerPermissionGuardIgnoreRole,
  deletePermissionGuard,
  isPrimaryUserGuard,
  ganbGuard,
} from "./guard";

const checkStart = (target, route) =>
  target.some((t) => route.path.startsWith(t));

export const guardInjection = (routes) => {
  routes.forEach((route) =>
    route.meta ? (route.meta.guard = []) : (route.meta = { guard: [] }),
  );
  isUseWorkflowGuardInjection(routes);
  readPermissionGuardInjection(routes);
  employeeReadPermissionGuardInjection(routes);
  registerPermissionGuardInjection(routes);
  cashFlowReadPermissionGuardInjection(routes);
  employeeInvitationGuardInjection(routes);
  employeeDeleteGuardInjection(routes);
  receivedInvoicingGuardInjection(routes);
  applicationListGuardInjection(routes);
  operationLogListGuardInjection(routes);
  isPrimaryUserGuardInjection(routes);
  fileDataListGuard(routes);
  fileDataRegisterInjection(routes);
  ganbGuardInjection(routes);
};

const isUseWorkflowGuardInjection = (routes) => {
  const target = ["/application"];
  routes.forEach((route) => {
    if (checkStart(target, route)) {
      route.meta.guard.push(isUseWorkflowGuard);
    }
  });
};

const isPrimaryUserGuardInjection = (routes) => {
  const target = ["/unsubscribe", "/unsubscribe-confirm"];
  routes.forEach((route) => {
    if (checkStart(target, route)) {
      route.meta.guard.push(isPrimaryUserGuard);
    }
  });
};

// TODO: receive-invoicing, cashflowが判定できない
const readPermissionGuardInjection = (routes) => {
  const target = ["/payment-list", "/invoicing-list"];
  routes.forEach((route) => {
    if (checkStart(target, route)) {
      const index = route.path.indexOf("-list");
      const type = route.path.slice(1, index); // /payment-list -> payment
      route.meta.guard.push(() => readPermissionGuard(type));
    }
  });
};

const employeeReadPermissionGuardInjection = (routes) => {
  const target = ["/employee-list", "/employee-edit"];
  const type = "employee";
  routes.forEach((route) => {
    if (checkStart(target, route)) {
      route.meta.guard.push(() => readPermissionGuard(type));
    }
  });
};

const employeeInvitationGuardInjection = (routes) => {
  const target = "/employee-invitation";
  const type = "employee";
  routes.forEach((route) => {
    if (route.path.startsWith(target)) {
      route.meta.guard.push(() => registerPermissionGuardIgnoreRole(type));
    }
  });
};

const employeeDeleteGuardInjection = (routes) => {
  const target = "/employee-delete";
  const type = "employee";
  routes.forEach((route) => {
    if (route.path.startsWith(target)) {
      route.meta.guard.push(() => deletePermissionGuard(type));
    }
  });
};

const cashFlowReadPermissionGuardInjection = (routes) => {
  const target = ["/cashflow-list"];
  const type = "balance";
  routes.forEach((route) => {
    if (checkStart(target, route)) {
      route.meta.guard.push(() => readPermissionGuard(type));
    }
  });
};

const registerPermissionGuardInjection = (routes) => {
  const target = "-register";
  routes.forEach((route) => {
    if (
      route.path.endsWith(target) &&
      !route.path.startsWith("/business-associate")
    ) {
      // 取引先登録は別のGuardInjectionで判定する
      const index = route.path.indexOf(target);
      const type = route.path.slice(1, index); // /payment-register -> payment
      route.meta.guard.push(() => registerPermissionGuard(type));
    }
  });
};

const receivedInvoicingGuardInjection = (routes) => {
  const target = "/receive-invoicing-list";
  const type = "receive-invoice";
  routes.forEach((route) => {
    if (route.path.startsWith(target)) {
      route.meta.guard.push(() => readPermissionGuard(type));
    }
  });
};

const applicationListGuardInjection = (routes) => {
  const target = "/application-list";
  const types = ["payment", "invoicing"];
  const guardRegister = () => {
    const result = types
      .map((type) => readPermissionGuard(type))
      .filter((e) => e instanceof PermissionError);
    if (result.length > 1) return result[0];
  };
  routes.forEach((route) => {
    if (route.path.startsWith(target)) {
      route.meta.guard.push(() => guardRegister());
    }
  });
};

const operationLogListGuardInjection = (routes) => {
  const target = "/workflow-list";
  const type = "operation-logs";
  routes.forEach((route) => {
    if (route.path.startsWith(target)) {
      route.meta.guard.push(() => readPermissionGuard(type));
    }
  });
};

const fileDataListGuard = (routes) => {
  const target = "/file-data-list";
  const type = "payment";
  routes.forEach((route) => {
    if (route.path.startsWith(target)) {
      route.meta.guard.push(() => readPermissionGuard(type));
    }
  });
};

const fileDataRegisterInjection = (routes) => {
  const target = "/file-data-upload";
  const type = "payment";
  routes.forEach((route) => {
    if (route.path.startsWith(target)) {
      route.meta.guard.push(() => registerPermissionGuard(type));
    }
  });
};

const ganbGuardInjection = (routes) => {
  const targets = [
    "/payment-register",
    "/file-data-list",
    "/file-data-upload",
    "/file-data-upload-confirm",
    "/file-data-upload-complete",
    "/file-data-detail",
    "/file-data-edit",
  ];

  routes.forEach((route) => {
    if (checkStart(targets, route)) {
      route.meta.guard.push(() => ganbGuard());
    }
  });
};
