import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Date } from "@/model/values/date";
import { Status } from "@/model/values/status";
import { Application } from "../application/application";

// 請求一覧画面__請求リスト部分
const properties = {
  id: 0,
  invoicingNumber: "",
  deadlineOn: Date.empty(),
  billingDate: Date.empty(),
  title: "",
  amountOfMoney: AmountOfMoney.empty(),
  status: Status.empty(),
  application: Application.empty(),
};

export class InvoicingListItem {
  static valueOf(items = {}) {
    return new InvoicingListItem(items);
  }
  static empty() {
    return new InvoicingListItem({});
  }
  constructor(items = properties) {
    Object.assign(this, items);
  }
  // get billingDate() {
  //   const { receivedInvoicing }  = this
  //   if (receivedInvoicing.isExist) {
  //     return receivedInvoicing.billingDate
  //   }
  //   return "-"
  // }
}
