import { OperationLogListItem } from "@/model/operation_log/operation_log_list_Item";
import { DateTime } from "@/model/values/date_time";
import { toCamel } from "@/service/convert_service";

export const operationLogListItemFromJson = (json) => {
  const {
    employeeId,
    operationNumber,
    employeeName,
    operationDatetime,
    operationType,
    operationDetail,
    operationDetailRemarks,
  } = toCamel(json);

  return OperationLogListItem.valueOf({
    id: employeeId,
    operationNumber,
    employeeName,
    operationDatetime: DateTime.valueOf(operationDatetime),
    operationType,
    operationDetail,
    operationDetailRemarks,
  });
};
