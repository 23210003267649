import { ReceivedInvoicing } from "@/model/received_invoicing/received_invoicing";

export default {
  namespaced: true,
  state: {
    invoicing: ReceivedInvoicing.empty(),
  },
  mutations: {
    storeInvoicing: (state, { invoicing }) =>
      (state.invoicing = ReceivedInvoicing.valueOf(invoicing)),
  },
  actions: {},
  getters: {
    invoicing: (state) => state.invoicing,
  },
};
