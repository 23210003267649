const properties = {
  information: 0,
  announcements: 0,
  push_notifications: 0,
  applying: 0,
  remand: 0,
  approval: 0,
  rejected: 0,
};

export class NotificationList {
  static valueOf(items = properties) {
    return new NotificationList({ ...properties, ...items });
  }
  static empty() {
    return NotificationList.valueOf();
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }

  get someTypeApplicationNotificationIsExist() {
    const { applying, approval, remand, rejected } = this;
    const ary = [applying, approval, remand, rejected];
    return ary.some((e) => e);
  }
}
