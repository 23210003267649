const properties = {
  id: "",
  name: "",
  code: "",
  branchList: [],
};

export class Bank {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    return new Bank({ ...properties, ...item });
  }

  static empty() {
    return Bank.valueOf({});
  }

  toString() {
    return this.name;
  }
}
