export const status = {
  unapplied: { name: "未取込", style: "bg", value: "unapplied" },
  applying: {
    name: "申請中",
    name_for_single_step_approver: "承認待ち",
    name_for_multi_step_approver: "第一承認待ち",
    style: "bg",
    value: "applying",
  },
  first_approval: {
    name: "第一承認済",
    name_for_second_approver: "第二承認待ち",
    value: "first_approval",
    style: "bg",
  },
  get_back: { name: "取り戻し", style: "bg", value: "get_back" },
  remand: { name: "差戻し", style: "bg", value: "remand" },
  withdraw: { name: "取り下げ", style: "text", value: "withdraw" },
  rejected: { name: "否認", style: "text", value: "rejected" },
  approval: { name: "承認済み", style: "bg", value: "approval" },
  payment_reservation: {
    name: "支払予約中",
    style: "text",
    value: "payment_reservation",
  },

  invoice_reservation: {
    name: "請求予約中",
    style: "text",
    value: "invoice_reservation",
  },
  invoiced: { name: "請求済", style: "text", value: "invoiced" },
  second_approval: { name: "承認済", value: "second_approval", style: "bg" },
  payment_data_not_created: {
    name: "支払未作成",
    value: "payment_data_not_created",
    style: "bg",
  },
  payment_converted: {
    name: "支払作成済",
    value: "payment_converted",
    style: "text",
  },
  paid: { name: "支払済", style: "text", value: "paid" },
  delete: { name: "", value: "delete", style: "" }, // db上は存在しないstatus
  sending_error: { name: "送金エラー", style: "text", value: "sending_error" },
  canceled: { name: "取り消し", style: "bg", value: "canceled" },
};

export const paymentStatus = () => {
  const {
    applying,
    first_approval,
    get_back,
    remand,
    withdraw,
    rejected,
    payment_reservation,
    paid,
    sending_error,
    canceled,
  } = status;

  return {
    applying,
    first_approval,
    get_back,
    remand,
    withdraw,
    rejected,
    payment_reservation,
    paid,
    sending_error,
    canceled,
  };
};

export const paymentFileStatus = () => {
  const { unapplied } = status;

  return { unapplied, ...paymentStatus() };
};

export const invoicingStatus = () => {
  const {
    applying,
    first_approval,
    get_back,
    remand,
    withdraw,
    rejected,
    invoice_reservation,
    invoiced,
    paid,
  } = status;
  const result = {
    applying,
    first_approval,
    get_back,
    remand,
    withdraw,
    rejected,
    invoice_reservation,
    invoiced,
    paid,
  };
  result.paid.name = "入金済";

  return result;
};

export const applicationStatusList = () => {
  const {
    applying,
    first_approval,
    get_back,
    remand,
    withdraw,
    rejected,
    payment_reservation,
    paid,
    sending_error,
    canceled,
  } = status;
  const result = {
    applying,
    first_approval,
    get_back,
    remand,
    withdraw,
    rejected,
    payment_reservation,
    paid,
    sending_error,
    canceled,
  };
  // result.paid.name = "支払/入金済";

  return result;
};

export const receivedInvoicingStatus = () => {
  const { payment_data_not_created, payment_converted } = status;

  return { payment_data_not_created, payment_converted };
};
