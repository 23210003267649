import { DateTime } from "@/model/values/date_time";
import { Date } from "@/model/values/date";
import { Status } from "@/model/values/status";
import { OperationEmployee } from "@/model/employee/operation_employee";
import { ApplicationType } from "@/model/values/application_type";
import { Operation } from "@/model/values/operation";
import { Employee } from "@/model/employee/employee";

const properties = {
  id: 0,
  operationEmployee: OperationEmployee.empty(),
  operation: Operation.empty(),
  operationAt: DateTime.empty(),
  status: Status.empty(),
  type: ApplicationType.empty(),
  applicant: Employee?.empty(),
  approvalDeadline: Date.empty(),
  applicationDate: Date.empty(),
  comment: "",
};

export class ApplicationHistoryItem {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    return new ApplicationHistoryItem(item);
  }

  static empty() {
    return ApplicationHistoryItem.valueOf({});
  }

  get isExist() {
    return this.id;
  }

  get operationDate() {
    return this.operationAt.date;
  }
  get operationTime() {
    return this.operationAt.time;
  }
}
