import { dummyWaitForLoading } from "@/infrastructure/dummy_repository_functions";
import { BalanceTransition } from "@/model/balance/balance_transition";
import { CashflowListItem } from "@/model/balance/cashflow_list_item";
import { ChartData } from "@/model/chart/chart_data";
import { Balance } from "@/model/values/balance";
import { Date } from "@/model/values/date";
import { toCamel } from "@/service/convert_service";

export class BalanceDummyRepository {
  fetchCashflowList = async ({ year, month }) => {
    console.log(`year is ${year}, month is ${month}`);
    await dummyWaitForLoading(0);

    return [
      CashflowListItem.valueOf({
        transactionAt: Date.valueOf("2021-05-14T09:06:11.000Z"),
        toMemberName: "test2",
        amount: Balance.valueOf(100),
        statusJa: "送金完了",
        prefix: "minus",
        balance: Balance.valueOf(1000),
      }),
      CashflowListItem.valueOf({
        transactionAt: Date.valueOf("2021-05-21T09:06:11.000Z"),
        toMemberName: null,
        statusJa: "オートチャージ",
        prefix: "minus",
        amount: Balance.valueOf(100),
        balance: Balance.valueOf(2000),
      }),
      CashflowListItem.valueOf({
        transactionAt: Date.valueOf("2021-06-21T09:06:11.000Z"),
        toMemberName: "test2",
        statusJa: "口座戻し完了",
        prefix: "plus",
        amount: Balance.valueOf(100),
        balance: Balance.valueOf(0),
      }),
      CashflowListItem.valueOf({
        transactionAt: Date.valueOf("2021-06-21T08:06:11.000Z"),
        toMemberName: null,
        statusJa: "入金完了",
        prefix: "plus",
        amount: Balance.valueOf(100),
        balance: Balance.valueOf(0),
      }),
    ];
  };
  fetchBalanceTransition = async (unit) => {
    console.log(`unit is ${unit}`);
    const data = [
      {
        date: "2021-01-20",
        balance: 1000,
        total_deposit: 1000,
        total_withdrawal: 2000,
      },
      {
        date: "2021-02-20",
        balance: 2000,
        total_deposit: 2000,
        total_withdrawal: 4000,
      },
      {
        date: "2021-03-20",
        balance: 300,
        total_deposit: 900,
        total_withdrawal: 50000,
      },
      {
        date: "2021-04-20",
        balance: 4000,
        total_deposit: 2000,
        total_withdrawal: 10,
      },
    ];
    const chartDataList = data.map((json) => ChartData.valueOf(toCamel(json)));
    return BalanceTransition.valueOf({ chartDataList });
  };
}
