import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth";
import option from "@/store/option";
import sms from "@/store/sms";
import application from "@/store/application";
import receivedInvoicing from "@/store/received_invoicing";
import progress from "@/store/progress";
import form from "@/store/form";
import bank from "@/store/bank";
import file from "@/store/file";
import notification from "@/store/notification";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    sms,
    auth,
    form,
    option,
    progress,
    application,
    receivedInvoicing,
    bank,
    file,
    notification,
  },
  plugins: [
    createPersistedState({
      key: "rakutasu",
      paths: [
        "application.pendingApproveIds",
        "form.numberOfData",
        "auth.smsAuthTokens",
      ],
    }),
  ],
});
