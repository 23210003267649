import { authInfoFromJson } from "@/dto/auth_info";
import Repository from "@/infrastructure/api";

const resource = "/me";
export class AuthInfoRepository {
  fetchAuthInfo = async (params = {}, config = {}, jwt = "") => {
    const headers = jwt ? { headers: { Authorization: `Bearer ${jwt}` } } : {};
    const { data } = await Repository.get(`${resource}`, {
      ...{ params },
      ...config,
      ...headers,
    });
    return authInfoFromJson(data);
  };
}
