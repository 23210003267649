import { PageNation } from "@/model/form/page_nation";
import { ApplicationType } from "@/model/values/application_type";
import { toSnake } from "@/service/convert_service";

const properties = {
  keyword: "",
  status: [],
  type: ApplicationType.empty(),
  pageNation: PageNation.firstPage(),
  sortDirection: "desc",
  sortColumn: "id",
};

export class ApplicationListSearchForm {
  constructor({
    keyword,
    status,
    type,
    pageNation,
    sortColumn,
    sortDirection,
  }) {
    this.keyword = keyword;
    this.status = status;
    this.type = type;
    this.pageNation = pageNation;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static valueOf(items = {}) {
    return new ApplicationListSearchForm({ ...properties, ...items });
  }

  static empty() {
    return ApplicationListSearchForm.valueOf(properties);
  }

  clear() {
    const { pageNation } = this;
    return ApplicationListSearchForm.valueOf({
      ...properties,
      ...{ pageNation },
    });
  }

  updateFreeWord(value) {
    return ApplicationListSearchForm.valueOf({
      ...this,
      ...{ keyword: value },
    });
  }
  updateStatus(value) {
    return ApplicationListSearchForm.valueOf({ ...this, ...{ status: value } });
  }
  updateType(value) {
    const type = this.type.updateType(value);
    return ApplicationListSearchForm.valueOf({ ...this, ...{ type } });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return ApplicationListSearchForm.valueOf({ ...this, ...{ pageNation } });
  }

  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return ApplicationListSearchForm.valueOf({ ...this, ...{ pageNation } });
  }
  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return ApplicationListSearchForm.valueOf({ ...this, ...{ pageNation } });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return ApplicationListSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return ApplicationListSearchForm.valueOf({
      ...this,
      ...{ sortColumn: value },
    });
  }

  toJson() {
    const { status, keyword, type, pageNation, sortColumn, sortDirection } =
      this;
    const params = pageNation.toJson();
    if (keyword) params.keyword = keyword;
    if (status.length) params.status = status;
    if (type.value) params.type = type.value;

    params.sort_direction = sortDirection;
    params.sort_column = sortColumn;

    return toSnake(params);
  }
}
