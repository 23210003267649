export const readPermissionCheck = (type, target) => {
  return target.some(
    (e) =>
      e.value.startsWith(`${type}_read`) || e.value.startsWith(`${type}_save`),
  );
};

// saveは自身しか無いので、suffixチェックは不要
export const registerPermissionCheck = (type, target) => {
  return target.some((e) => e.value.startsWith(`${type}_save`));
};

// 支払の権限は現状1つのみ
export const receivedInvoicingPaymentPermissionCheck = (type, target) => {
  return target.some((e) => e.value === `${type}_payment_other`);
};

export const updatePermissionCheck = (type, targets, isSelf) => {
  const permissionName = addPermissionNameSuffix(
    `${type}_update`,
    type,
    isSelf,
  );
  return targets.some((target) => target.value === permissionName);
};

export const deletePermissionCheck = (type, targets, isSelf) => {
  const permissionName = addPermissionNameSuffix(
    `${type}_delete`,
    type,
    isSelf,
  );
  return targets.some((target) => target.value === permissionName);
};

export const exactPermissionCheck = (name, permissions) => {
  return permissions.some((e) => e.value === name);
};

export const permissionCheck = (name, permissions) => {
  return permissions.some((e) => e.startsWith(name));
};

const addPermissionNameSuffix = (permissionName, type, isSelf) => {
  if (
    [
      "payment",
      "invoicing",
      "operation-logs",
      "balance",
      "receive-invoice",
    ].includes(type)
  ) {
    permissionName += isSelf ? "_self" : "_other";
  }
  return permissionName;
};
