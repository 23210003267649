import { accountType } from "@/const/file_upload";
import { Date } from "@/model/values/date";
import { Bank } from "../bank/bank";
import { Branch } from "../bank/branch";
import { AmountOfMoney } from "../values/amount_of_money";
import { Status } from "../values/status";
import { operation } from "@/const/operation";

const properties = {
  paymentId: 0,
  fileId: 0,
  uploadId: "",
  fileName: "",
  fileType: "",
  uploadDate: Date.empty(),
  status: Status.empty(),
  paymentDate: Date.empty(),
  amountOfMoney: AmountOfMoney.empty(),
  fee: AmountOfMoney.empty(),
  applicationDate: Date.empty(),
  approvalDeadline: Date.empty(),
  recordCount: 0,
  recipientName: "",
  clientSpecifiedId: "",
  bank: Bank.empty(),
  branch: Branch.empty(),
  accountType: accountType.id,
  accountNumber: "",
  paymentDetails: [],
  applicationHistory: [],
  memo: "",
  errorReason: "",
  transferKind: "",
};

export class FileDataDetail {
  static valueOf(items = properties) {
    return new FileDataDetail({ ...properties, ...items });
  }
  static empty() {
    return new FileDataDetail();
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }

  get latestApplying() {
    if (!this.applicationHistory.length) return {};
    return this.applicationHistory.find((e) =>
      [operation.apply.value, operation.reapply.value].includes(
        e.operation.value,
      ),
    );
  }
}
