import Repository from "@/infrastructure/api";
import { RegisterAuth } from "@/model/auth_info/register_auth";
import { toCamel } from "@/service/convert_service";

const resource = "invitation";
export class InvitationRepository {
  invitationEmployee = async (payload, config = {}) =>
    await Repository.post(`${resource}/employee`, payload, config);

  authenticateToken = async (payload, config = {}) => {
    const { data } = await Repository.post(
      `${resource}/employee/auth`,
      payload,
      config,
    );
    const params = toCamel(data);
    return RegisterAuth.valueOf(params);
  };
}
