import { Date } from "@/model/values/date";
import { AmountOfMoney } from "../values/amount_of_money";
import { BankAccount } from "../bank/bank_account";

const properties = {
  targetMonth: Date.empty(),
  paymentDeadline: Date.empty(),
  billingAmount: AmountOfMoney.empty(),
  bankAccountInformation: BankAccount.empty(),
};

export class ServiceBill {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new ServiceBill({ ...properties, ...items });
  }
  static empty() {
    return ServiceBill.valueOf();
  }
}
