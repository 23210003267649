import { Role } from "@/model/values/role";

export class Applicant extends Role {
  constructor(value) {
    super(value);
  }
  static valueOf(value) {
    return new Applicant(value);
  }
  static empty() {
    return new Applicant(null);
  }
}
