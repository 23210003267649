const properties = {
  dataCount: 0,
  listItems: [],
};

export class OperationLogList {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = {}) {
    return new OperationLogList(items);
  }
  static empty() {
    return new OperationLogList({});
  }
}
