import { AmountOfMoney } from "@/model/values/amount_of_money";

import { Date } from "@/model/values/date";
import { toSnake } from "@/service/convert_service";
import { Application } from "@/model/application/application";

const properties = {
  id: 0,
  invoicingNumber: "",
  deadlineOn: Date.empty(),
  billingDate: Date.empty(),
  amountOfMoney: AmountOfMoney.empty(),
  title: "",
  memo: "",
  application: Application.empty(),
};

export class InvoicingEdit {
  static valueOf(items = properties) {
    return new InvoicingEdit({ ...properties, ...items });
  }
  static empty() {
    return InvoicingEdit.valueOf();
  }
  constructor(items = properties) {
    Object.assign(this, items);
  }

  toJson() {
    const {
      id,
      invoicingNumber,
      deadlineOn,
      title,
      amountOfMoney,
      billingDate,
      memo,
      application,
    } = this;

    const params = {
      invoicingId: id,
      invoicingNumber,
      title,
      billingDate: billingDate.toParam(),
      deadlineOn: deadlineOn.toParam(),
      amountOfMoney: amountOfMoney.value,
      memo,
    };
    if (application.isExist) {
      Object.assign(params, {
        approvalDeadline: application.approvalDeadline.toParam(),
      });
    }
    return toSnake(params);
  }

  updateInvoicingNumber(value) {
    // validateなど
    const properties = { ...this, ...{ invoicingNumber: value } };
    return InvoicingEdit.valueOf(properties);
  }
  updateBillingDate(value) {
    const properties = { ...this, ...{ billingDate: value } };
    return InvoicingEdit.valueOf(properties);
  }
  updateTitle(value) {
    const properties = { ...this, ...{ title: value } };
    return InvoicingEdit.valueOf(properties);
  }
  updateAmountOfMoney(value) {
    const amountOfMoney = this.amountOfMoney.updateValue(value);
    return InvoicingEdit.valueOf({ ...this, ...{ amountOfMoney } });
  }
  updateMemo(value) {
    const properties = { ...this, ...{ memo: value } };
    return InvoicingEdit.valueOf(properties);
  }
  updateDeadlineOn(value) {
    const properties = { ...this, ...{ deadlineOn: value } };
    return InvoicingEdit.valueOf(properties);
  }
  updateApprovalDeadline(value) {
    const application = this.application.updateApprovalDeadline(value);
    return InvoicingEdit.valueOf({ ...this, ...{ application } });
  }
}
