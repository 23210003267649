import Repository from "@/infrastructure/api";

const resource = "login";
export class LoginRepository {
  register = async (payload, config = {}) => {
    const { data } = await Repository.post(`${resource}`, payload, config);
    return { jwt: data["jwt_token"], smsAuthToken: data["sms_auth_token"] };
  };
  logout = async () => {
    await Repository.post("logout");
  };
}
