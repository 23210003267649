export const operation = {
  apply: { name: "申請", value: "apply", style: "bg" },
  approval: { name: "承認", value: "approval", style: "text" },
  comment: { name: "コメント", value: "comment", style: "bg" },
  get_back: { name: "取り戻し", value: "get_back", style: "bg" },
  first_approval: { name: "第一承認", value: "first_approval", style: "bg" },
  reapply: { name: "再申請", value: "reapply", style: "bg" },
  reject: { name: "否認", value: "reject", style: "text" },
  remand: { name: "差戻し", value: "remand", style: "bg" },
  second_approval: { name: "第二承認", value: "second_approval", style: "bg" },
  withdraw: { name: "取り下げ", value: "withdraw", style: "text" },
  cancel: { name: "取り消し", value: "canceled", style: "bg" },
};
