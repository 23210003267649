import { NotificationList } from "@/model/notification/notification_list";
import { toCamel } from "@/service/convert_service";

export const notificationListFromJson = (json) => {
  const listItems = json.map((e) => {
    const { notificationNumber, type } = toCamel(e);
    return [type, notificationNumber];
  });

  return NotificationList.valueOf(Object.fromEntries(listItems));
};
