import Repository from "@/infrastructure/api";
import { informationListItemFromJson } from "@/dto/information";
import { InformationList } from "@/model/information/information_list";

const resource = "informations";
export class InformationRepository {
  fetchInformationList = async (params, config = {}) => {
    const { data } = await Repository.get(`${resource}`, {
      ...{ params },
      ...config,
    });
    const dataCount = data["data_count"];
    const listItems = data["current_information_list"].map((json) =>
      informationListItemFromJson(json),
    );
    return InformationList.valueOf({ dataCount, listItems });
  };
  updateIsRead = async (informationId) => {
    const { data } = await Repository.put(`${resource}/${informationId}/read`);
    return informationListItemFromJson(data);
  };
}
