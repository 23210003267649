import { Date } from "@/model/values/date";
import { toSnake } from "@/service/convert_service";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Status } from "../values/status";
import { Application } from "../application/application";

const properties = {
  paymentId: 0,
  fileId: 0,
  fileName: "",
  amountOfMoney: AmountOfMoney.empty(),
  fee: AmountOfMoney.empty(),
  application: Application.empty(),
  paymentDate: Date.empty(),
  memo: "",
  paymentDetails: [],
  status: Status.empty(),
  fileType: "",
};

export class FileDataEdit {
  static valueOf(items = properties) {
    return new FileDataEdit({ ...properties, ...items });
  }
  static empty() {
    return FileDataEdit.valueOf();
  }
  constructor(items = properties) {
    Object.assign(this, items);
  }

  toJson() {
    const { paymentId, paymentDate, memo, application, paymentDetails } = this;

    const fileData = {
      paymentId,
      paymentDate: paymentDate.toParam(),
      memo,
    };

    if (application.isExist) {
      Object.assign(fileData, {
        application: {
          approval_deadline: application.approvalDeadline.toParam(),
        },
      });
    }

    const fileDataDetails = paymentDetails.map((detail) => {
      const {
        lineNumber,
        type,
        bank,
        branch,
        accountType,
        accountNumber,
        recipientName1,
        recipientNameKana,
        amountOfMoney,
        fee,
        clientSpecifiedId,
      } = detail;

      return toSnake({
        lineNumber,
        type,
        bankCode: bank.code,
        branchCode: branch.code,
        accountType: accountType.id,
        accountNumber,
        recipientName1,
        recipientNameKana,
        amountOfMoney: amountOfMoney.value,
        fee: fee.value,
        clientSpecifiedId,
      });
    });

    return { fileData: toSnake(fileData), fileDataDetails };
  }

  updatePaymentDate(value) {
    const properties = { ...this, ...{ paymentDate: Date.valueOf(value) } };
    return FileDataEdit.valueOf(properties);
  }
  updateMemo(value) {
    const properties = { ...this, ...{ memo: value } };
    return FileDataEdit.valueOf(properties);
  }
  updateApprovalDeadline(value) {
    const application = this.application.updateApprovalDeadline(value);
    return FileDataEdit.valueOf({ ...this, application });
  }
}
