export const roles = {
  applicant: { name: "申請者", value: "applicant" },
  approver: { name: "承認者", value: "approver" },
  second_approver: { name: "第二承認者", value: "second_approver" },
};

export const rolesExcludeSecondeApprover = () => {
  const { applicant, approver } = roles;

  return { applicant, approver };
};

export const rolesAll = () => {
  return roles;
};
