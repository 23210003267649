export default {
  namespaced: true,
  state: {
    banks: [],
  },
  mutations: {
    updateBanks: (state, payload) => (state.banks = payload),
  },
  actions: {},
  getters: {
    banks: (state) => state.banks,
  },
};
