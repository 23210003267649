import router from "@/router";

export const goBack = () => router.back();
export const pageReload = () => router.go();

export const moveRoot = () => router.push({ path: "/" }).catch(() => {});
export const moveLoginOnReplace = () => {
  // リロードしてstoreをリセットする
  router.replace({ path: "/login" }).then(() => router.go());
};
export const moveLoginPasswordSendMail = () =>
  router.push({ path: "/login-password-send-mail" });
export const moveLoginPasswordSendMailConfirm = () =>
  router.push({ path: "/login-password-send-mail-confirm" });

export const moveSmsAuthenticate = () =>
  router.push({ path: "/sms-authenticate" });

export const moveHome = () => router.push({ path: "/home" });
export const moveHomeOnReplace = () => router.replace({ path: "/home" });

export const moveAccount = () => router.push({ path: "/account" });

export const moveNewList = () => router.push({ path: "/news-list" });

export const moveLoginRegisterComplete = () =>
  router.push({ path: "/login-register-complete" });

// 請求
export const moveInvoicingList = () => router.push({ path: "/invoicing-list" });
export const moveInvoicingDetail = (invoicingId) =>
  router.push({ path: `/invoicing-detail/${invoicingId}` });
export const moveInvoicingEdit = (invoicingId) =>
  router.push({ path: `/invoicing-edit/${invoicingId}` });
export const moveInvoicingRegister = () =>
  router.push({ path: `/invoicing-register` });

// 社員
export const moveEmployeeList = () => router.push({ path: "/employee-list" });
export const moveEmployeeEdit = (employeeId) =>
  router.push({ path: `/employee-edit/${employeeId}` });
export const moveEmployeeDelete = (employeeId) =>
  router.push({ path: `/employee-delete/${employeeId}` });
export const moveEmployeeInvitation = () =>
  router.push({ path: "/employee-invitation" });

// 支払い
export const movePaymentList = () => router.push({ path: "/payment-list" });
export const movePaymentDetail = (paymentId) =>
  router.push({ path: `/payment-detail/${paymentId}` });
export const movePaymentEdit = (paymentId) =>
  router.push({ path: `/payment-edit/${paymentId}` });
export const movePaymentRegister = () =>
  router.push({ path: "/payment-register" });

// 受領請求
export const moveReceiveInvoicingList = () =>
  router.push({ path: `/receive-invoicing-list` });
export const moveReceiveInvoicingDetail = () =>
  router.push({ path: `/receive-invoicing-detail` });

// 申請
export const moveApplicationList = () =>
  router.push({ path: "/application-list" });
export const moveApplicationApprovalPayment = (paymentId) =>
  router.push({ path: `/application-approval-payment/${paymentId}` });
export const moveApplicationApprovalInvoicing = (invoicingId) =>
  router.push({ path: `/application-approval-invoicing/${invoicingId}` });
export const moveApplicationInvoicingEdit = (id) =>
  router.push({ path: `/application-invoicing-edit/${id}` });

export const moveTerms = () =>
  window.open(router.resolve("/terms").href, "_blank", "noopener noreferrer");
export const moveFaq = () =>
  window.open(router.resolve("/faq").href, "_blank", "noopener noreferrer");

export const moveNotFound = () => router.replace({ path: "/not-found" });

// 解約
export const moveUnsubscribe = () => router.push({ path: "/unsubscribe" });
export const moveUnsubscribeConfirm = () =>
  router.push({ path: "/unsubscribe-confirm" });
export const moveUnsubscribeQuestionnaire = (id) =>
  router.push({ path: `/unsubscribe-questionnaire/${id}` });
export const moveUnsubscribeComplete = () =>
  router.push({ path: `/unsubscribe-complete` });

// データ連携
export const moveFileDataList = () => router.push({ path: "/file-data-list" });
export const moveFileDataDetail = (id) =>
  router.push({ path: `/file-data-detail/${id}` });
export const moveFileDataEdit = (id) =>
  router.push({ path: `/file-data-edit/${id}` });
export const moveFileDataUpload = () =>
  router.push({ path: "/file-data-upload" });
export const moveFileDataUploadConfirm = () =>
  router.push({ path: "/file-data-upload-confirm" });
export const moveFileDataUploadComplete = ({ fileId, fileTitle, fileType }) =>
  router.push({
    name: "FileDataUploadComplete",
    params: { fileId, fileTitle, fileType },
  });

export const moveErrorServiceBill = () =>
  router.push({ path: "/error-service-bill" });

export const moveExternalSmsAuthenticate = (token) =>
  router.push({ path: `/external/payment/sms-authenticate?token=${token}` });
