import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Date } from "@/model/values/date";
import { Status } from "@/model/values/status";
import { Application } from "../application/application";

const properties = {
  id: 0,
  invoicingNumber: "",
  billingDate: Date.empty(),
  amountOfMoney: AmountOfMoney.empty(),
  deadlineOn: Date.empty(),
  memo: "",
  title: "",
  status: Status.empty(),
  application: Application.empty(),
  applicationHistory: [],
};

export class InvoicingDetail {
  static valueOf(items = properties) {
    return new InvoicingDetail({ ...properties, ...items });
  }
  static empty() {
    return InvoicingDetail.valueOf({});
  }
  constructor(items = properties) {
    Object.assign(this, items);
  }
  get latestApplying() {
    if (!this.applicationHistory.length) return {};
    return this.applicationHistory.find((e) => e.status.isApplying);
  }

  get latestApplyingEmployeeName() {
    if (!this.applicationHistory.length) return "";
    const applying = this.applicationHistory.find((e) => e.status.isApplying);
    return `${applying.lastName}${applying.firstName}`;
  }
}
