import { Employee } from "@/model/employee/employee";
import { Member } from "@/model/member/member";

const properties = {
  employee: Employee.empty(),
  company: Member.empty(),
  authenticated: false,
};

export class AuthInfo {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    const items = { ...properties, ...item };
    return new AuthInfo(items);
  }

  static empty() {
    return AuthInfo.valueOf();
  }

  get usernameShort() {
    const { lastName, firstName } = this.employee;
    return `${lastName} ${firstName}`;
  }
  get displayCompanyName() {
    const { displayCompanyName } = this.company;
    return displayCompanyName;
  }
  get mainPhoneNumber() {
    const { corporatePhoneNumber } = this.company;
    return corporatePhoneNumber;
  }

  get useSecondApprove() {
    return this.numberOfApprovalStep > 1;
  }

  updateCellphoneNumber(value) {
    const employee = this.employee.updateCellphoneNumber(value);
    return AuthInfo.valueOf({ ...this, ...{ employee } });
  }

  get role() {
    return this.employee.permission.role;
  }

  get functionPermissions() {
    return this.employee.functionPermissions;
  }

  get numberOfApprovalStep() {
    return this.company.numberOfApprovalStep;
  }

  get isUseWorkflow() {
    return this.company.isUseWorkflow;
  }
}
