import { SmsAuthenticateBehavior } from "@/controller/sms/sms_authenticate_behavior";
import { RegisterAuth } from "@/model/auth_info/register_auth";
import { setupRepository } from "@/router";
import { handleRequest } from "@/service/handle_service";
import { moveSmsAuthenticate } from "@/service/router_service";

// TODO: 将来的にactionsにsms認証処理かきたい
export default {
  namespaced: true,
  state: {
    phoneNumber: "",
    registerAuth: RegisterAuth.empty(),
    callback: undefined,
    isRegister: false,
  },
  mutations: {
    updateCallback: (state, { callback }) => (state.callback = callback),
    updateRegisterAuth: (state, { registerAuth }) =>
      (state.registerAuth = registerAuth),
    updatePhoneNumber: (state, { phoneNumber }) =>
      (state.phoneNumber = phoneNumber),
    updateIsRegister: (state, { isRegister }) =>
      (state.isRegister = isRegister),
    clear: (state) => {
      state.phoneNumber = "";
      state.registerAuth = RegisterAuth.empty();
      state.callback = undefined;
      state.isRegister = false;
    },
  },
  actions: {
    tryIssue: (
      { commit },
      { phoneNumber, registerAuth, callback, isRegister = false },
    ) => {
      const interact = SmsAuthenticateBehavior.create({
        repository: setupRepository.sms,
      });
      const executeCallback = () => {
        commit("updatePhoneNumber", { phoneNumber });
        commit("updateRegisterAuth", { registerAuth });
        commit("updateCallback", { callback });
        commit("updateIsRegister", { isRegister });
        moveSmsAuthenticate();
      };
      handleRequest(
        () =>
          interact.tryIssue(phoneNumber, {
            Authorization: `Bearer ${registerAuth.jwtToken}`,
          }),
        executeCallback,
      );
    },
    tryIssueWithModal: ({ commit }, { phoneNumber }) => {
      const interact = SmsAuthenticateBehavior.create({
        repository: setupRepository.sms,
      });
      const executeCallback = () =>
        commit("updatePhoneNumber", { phoneNumber });
      handleRequest(() => interact.tryIssue(phoneNumber), executeCallback);
    },
    // tryUpdatePhoneNumber: (
    //   { commit },
    //   { phoneNumber, registerAuth, callback }
    // ) => {
    //   const interact = SmsAuthenticateBehavior.create({
    //     repository: setupRepository.sms,
    //   });
    //   const executeCallback = () => {
    //     commit("updatePhoneNumber", { phoneNumber });
    //     commit("updateRegisterAuth", { registerAuth });
    //     commit("updateCallback", { callback });
    //     moveSmsAuthenticate();
    //   };
    //   handleRequest(
    //     () => interact.tryUpdatePhoneNumber(phoneNumber),
    //     executeCallback
    //   );
    // },
  },
  getters: {
    phoneNumber: (state) => state.phoneNumber,
    registerAuth: (state) => state.registerAuth,
    jwt: (state) => state.registerAuth.jwtToken,
  },
};
