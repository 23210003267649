import { NotificationList } from "@/model/notification/notification_list";

export default {
  namespaced: true,
  state: {
    notificationList: NotificationList.empty(),
  },
  mutations: {
    updateNotificationList: (state, payload) =>
      (state.notificationList = payload),
    updateCount: (state, type) =>
      (state.notificationList = {
        ...state.notificationList,
        [type]: state.notificationList[type] - 1,
      }),
  },
  actions: {},
  getters: {
    notificationList: (state) => state.notificationList,
  },
};
