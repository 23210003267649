import { accountInfoFromJson } from "@/dto/account";
import Repository from "@/infrastructure/api";

const resource = "account";
export class AccountRepository {
  fetchThisMonthAccountInfo = async (config = {}) => {
    const { data } = await Repository.get(`${resource}/statement`, config);
    return accountInfoFromJson(data);
  };
}
