export const functionPermissions = {
  payment: {
    name: "支払い",
    self: [
      { name: "登録", value: "payment_save_self" },
      { name: "変更", value: "payment_update_self" },
      { name: "削除", value: "payment_delete_self" },
    ],
    other: [
      { name: "閲覧", value: "payment_read_other" },
      { name: "変更", value: "payment_update_other" },
      { name: "削除", value: "payment_delete_other" },
    ],
  },
  receiveInvoicing: {
    name: "受領請求",
    other: [
      { name: "閲覧", value: "receive-invoice_read_other" },
      { name: "支払い", value: "receive-invoice_payment_other" },
      { name: "差戻し", value: "receive-invoice_remand_other" },
    ],
  },
  invoicing: {
    name: "請求",
    self: [
      { name: "登録", value: "invoicing_save_self" },
      { name: "変更", value: "invoicing_update_self" },
      { name: "削除", value: "invoicing_delete_self" },
    ],
    other: [
      { name: "閲覧", value: "invoicing_read_other" },
      { name: "変更", value: "invoicing_update_other" },
      { name: "削除", value: "invoicing_delete_other" },
    ],
  },
  balance: {
    name: "入出金明細",
    self: [{ name: "閲覧", value: "balance_read_self" }],
    other: [{ name: "閲覧", value: "balance_read_other" }],
  },
  operationLogs: {
    name: "処理履歴",
    self: [{ name: "閲覧", value: "operation-logs_read_self" }],
    other: [{ name: "閲覧", value: "operation-logs_read_other" }],
  },
  employee: {
    name: "社員",
    values: [
      { name: "閲覧", value: "employee_read" },
      { name: "登録", value: "employee_save" },
      { name: "変更", value: "employee_update" },
      { name: "削除", value: "employee_delete" },
    ],
  },
  businessAssociates: {
    name: "取引先",
    values: [
      { name: "閲覧", value: "business-associates_read" },
      { name: "登録", value: "business-associates_save" },
      { name: "変更", value: "business-associates_update" },
      { name: "削除", value: "business-associates_delete" },
    ],
  },
  member: {
    name: "会員情報",
    values: [
      { name: "閲覧", value: "member_read" },
      { name: "変更", value: "member_update" },
    ],
  },
};
