import { dummyWaitForLoading } from "@/infrastructure/dummy_repository_functions";

export class LoginDummyRepository {
  register = async (payload) => {
    console.info(`login register payload is ${JSON.stringify(payload)}`);

    const { login_id, password } = payload;
    if (login_id !== "test1@example.com" || password !== "password") {
      // throw new Error("invalid id or password ")
    }

    await dummyWaitForLoading(500);
    return {
      jwt: "eyJhbGciOiJSUzI1NiJ9.eyJpZCI6MSwiaXNzIjoicmFrdXRhc3VfcGx1c19hcGkiLCJleHAiOjE2MzA0NjcyNDd9.IyZeouD_vvPl9On5rsuPFgwYBymsDLUweGL1UHbVuKkmx05wAjADBrLAMxZMEKEo0i9vQuytUSHSBA4k0cnTjA8xMistg2gdVUv_el43vXhKVttg6PMpdxA4b932rlajSfWZBIuUic3m6umw1_cGVro1_-t2dG4SH-mxx3B8Wcg",
    };
  };
}
