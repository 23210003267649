import Repository from "@/infrastructure/api";

const resource = "ganb";
export class GanbRepository {
  accountCreate = async () => {
    const { data } = await Repository.post(
      `${resource}/account_creation_request`,
    );
    return data["account_creation_url"];
  };
  authorizationRequest = async () => {
    const { data } = await Repository.post(`${resource}/authorization_request`);
    return data["authorization_url"];
  };
}
