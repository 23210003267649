import { format } from "@/service/date_service";
import dayjs from "@/dayjs";

export class DateTime {
  constructor(value) {
    this.value = value;
  }

  static empty() {
    return DateTime.valueOf(null);
  }

  static valueOf(value) {
    return new DateTime(dayjs.utc(value));
  }

  toString() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY/MM/DD HH:mm");
  }

  get date() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY/MM/DD");
  }

  get time() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return value.local().format("HH:mm");
  }

  get hms() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return value.local().format("HH:mm:ss");
  }

  get isExist() {
    return this.value.isValid();
  }
}
