import { Date } from "@/model/values/date";
import { Permission } from "@/model/values/permission";
import { toSnake } from "@/service/convert_service";
import { EmployeeStatus } from "./employee_status";

const properties = {
  id: 0,
  employeeNumber: "",
  lastName: "",
  firstName: "",
  lastNameKana: "",
  firstNameKana: "",
  birthday: Date.empty(),
  cellphoneNumber: "",
  email: "",
  isPrimaryUser: false,
  isDeleted: false,
  status: EmployeeStatus.empty(),
  permission: Permission.empty(),
  notificationSettings: [],
  accountLockedAt: Date.empty(),
  hasTransactionPassword: false,
};
export class Employee {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    return new Employee({ ...properties, ...item });
  }

  static empty() {
    return Employee.valueOf();
  }

  static registerInit() {
    const birthday = Date.valueOf("1970/01/01");
    return Employee.valueOf({ birthday });
  }

  toString() {
    return this.lastName + this.firstName;
  }

  updateEmployeeNumber(value) {
    const properties = { ...this, ...{ employeeNumber: value } };
    return Employee.valueOf(properties);
  }
  updateFirstName(value) {
    const properties = { ...this, ...{ firstName: value } };
    return Employee.valueOf(properties);
  }
  updateFirstNameKana(value) {
    const properties = { ...this, ...{ firstNameKana: value } };
    return Employee.valueOf(properties);
  }
  updateLastName(value) {
    const properties = { ...this, ...{ lastName: value } };
    return Employee.valueOf(properties);
  }
  updateLastNameKana(value) {
    const properties = { ...this, ...{ lastNameKana: value } };
    return Employee.valueOf(properties);
  }
  updateBirthday(value) {
    return Employee.valueOf({ ...this, birthday: Date.valueOf(value) });
  }
  updateEmail(value) {
    const properties = { ...this, ...{ email: value } };
    return Employee.valueOf(properties);
  }
  updateCellphoneNumber(value) {
    const properties = { ...this, ...{ cellphoneNumber: value } };
    return Employee.valueOf(properties);
  }
  updateRole(value) {
    const permission = this.permission.updateWorkflowPermission(value);
    const properties = { ...this, ...{ permission } };
    return Employee.valueOf(properties);
  }
  updateFunctionPermission(value) {
    const permission = this.permission.updateFunctionsPermission(value);
    const properties = { ...this, ...{ permission } };
    return Employee.valueOf(properties);
  }
  updateFunctionPermissions(value) {
    const permission = this.permission.updateFunctionsPermissions(value);
    const properties = { ...this, ...{ permission } };
    return Employee.valueOf(properties);
  }
  updateAllFunctionPermissions(value) {
    const permission = this.permission.updateAllFunctionsPermissions(value);
    const properties = { ...this, ...{ permission } };
    return Employee.valueOf(properties);
  }
  updateClearFunctionPermissions() {
    const permission = this.permission.updateClearFunctionsPermissions();
    const properties = { ...this, ...{ permission } };
    return Employee.valueOf(properties);
  }

  get role() {
    return this.permission.role;
  }
  get functionPermissions() {
    return this.permission.functionsPermission;
  }

  get employeeNameFull() {
    const { lastName, lastNameKana, firstName, firstNameKana } = this;
    if (!lastNameKana && !firstNameKana) {
      return `${lastName}${firstName}`;
    } else {
      return `${lastName}${firstName}（${lastNameKana}${firstNameKana}）`;
    }
  }
  get fullName() {
    return `${this.lastName}${this.firstName}`;
  }

  toUpdateParams() {
    const {
      employeeNumber,
      lastName,
      firstName,
      lastNameKana,
      firstNameKana,
      birthday,
      permission,
    } = this;
    return toSnake({
      employeeNumber,
      lastName,
      lastNameKana,
      firstName,
      firstNameKana,
      birthday: birthday.toParam(),
      permission: permission.toJson(),
    });
  }

  toJson() {
    const {
      employeeNumber,
      lastName,
      firstName,
      lastNameKana,
      firstNameKana,
      birthday,
      permission,
    } = this;
    return toSnake({
      employeeNumber,
      lastName,
      lastNameKana,
      firstName,
      firstNameKana,
      birthday: birthday.toParam(),
      permission: permission.toParam(),
    });
  }
}
