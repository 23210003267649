import { PaymentListSearchForm } from "@/controller/payment/payment_list_search_form";
import { ReceivedInvoicingListSearchForm } from "@/controller/receive_invoicing/receive_invoicing_list_search_form";
import { ApplicationListSearchForm } from "@/model/application/application_list_search_form";
import { CashflowListSearchForm } from "@/model/balance/cashflow_list_search_form";
import { EmployeeListSearchForm } from "@/model/employee/employee_list_search_form";
import { InvoicingListSearchForm } from "@/model/invoicing/invoicing_list_search_form";
import { WorkFlowSearchForm } from "@/model/operation_log/work_flow_search_form";
import { EmployeeDeleteSearchForm } from "@/model/employee/employee_delete_search_form";
import { FileDataListSearchForm } from "@/controller/file_data/file_data_list_search_form";
import { FileDataUploadConfirmSearchForm } from "@/controller/file_data/file_data_upload_confirm_search_form";
import { PageNation } from "@/model/form/page_nation";

const initState = {
  paymentListSearchForm: PaymentListSearchForm.empty(),
  invoicingListSearchForm: InvoicingListSearchForm.empty(),
  receivedInvoicingListSearchForm: ReceivedInvoicingListSearchForm.empty(),
  applicationListSearchForm: ApplicationListSearchForm.empty(),
  cashflowListSearchForm: CashflowListSearchForm.empty(),
  employeeListSearchForm: EmployeeListSearchForm.empty(),
  workflowListSearchForm: WorkFlowSearchForm.empty(),
  employeeDeleteSearchForm: EmployeeDeleteSearchForm.empty(),
  fileDataListSearchForm: FileDataListSearchForm.empty(),
  fileDataUploadConfirmSearchForm: FileDataUploadConfirmSearchForm.empty(),
  newsListSearchForm: PageNation.empty(),
  numberOfData: {
    payment: 10,
    invoicing: 10,
    receivedInvoicing: 10,
    application: 10,
    cashflow: 10,
    employee: 10,
    workflow: 10,
    employeeDelete: 10,
    fileData: 10,
    fileDataConfirm: 10,
    news: 10,
  },
};

export default {
  namespaced: true,
  state: {
    ...initState,
  },
  mutations: {
    thisMonthScheduledInvoicingListSearchForm: (state) =>
      (state.invoicingListSearchForm =
        InvoicingListSearchForm.thisMonthScheduled()),
    thisMonthNotPaidInvoicingListSearchForm: (state) =>
      (state.invoicingListSearchForm =
        InvoicingListSearchForm.thisMonthNotPaid()),
    updatePaymentListSearchForm: (state, { form }) => {
      state.paymentListSearchForm = form;
      state.numberOfData.payment = form.pageNation.numberOfData;
    },
    updateInvoicingListSearchForm: (state, { form }) => {
      state.invoicingListSearchForm = form;
      state.numberOfData.invoicing = form.pageNation.numberOfData;
    },
    updateReceivedInvoicingListSearchForm: (state, { form }) => {
      state.receivedInvoicingListSearchForm = form;
      state.numberOfData.receivedInvoicing = form.pageNation.numberOfData;
    },
    updateApplicationListSearchForm: (state, { form }) => {
      state.applicationListSearchForm = form;
      state.numberOfData.application = form.pageNation.numberOfData;
    },
    updateCashflowListSearchForm: (state, { form }) => {
      state.cashflowListSearchForm = form;
      state.numberOfData.cashflow = form.pageNation.numberOfData;
    },
    updateEmployeeListSearchForm: (state, { form }) => {
      state.employeeListSearchForm = form;
      state.numberOfData.employee = form.pageNation.numberOfData;
    },
    updateWorkflowListSearchForm: (state, { form }) => {
      state.workflowListSearchForm = form;
      state.numberOfData.workflow = form.pageNation.numberOfData;
    },
    updateEmployeeDeleteSearchForm: (state, { form }) => {
      state.employeeDeleteSearchForm = form;
      state.numberOfData.employeeDelete = form.pageNation.numberOfData;
    },
    updateFileDataListSearchForm: (state, { form }) => {
      state.fileDataListSearchForm = form;
      state.numberOfData.fileData = form.pageNation.numberOfData;
    },
    updateFileDataUploadListSearchForm: (state, { form }) => {
      state.fileDataUploadConfirmSearchForm = form;
      state.numberOfData.fileDataConfirm = form.pageNation.numberOfData;
    },
    updateNewsListSearchForm: (state, { form }) => {
      state.newsListSearchForm = form;
      state.numberOfData.news = form.numberOfData;
    },
    clear: (state) => (state = { ...state, ...initState }),
  },
  actions: {},
  getters: {
    paymentListSearchForm: (state) =>
      state.paymentListSearchForm.updateNumberOfData(
        state.numberOfData.payment,
      ),
    invoicingListSearchForm: (state) =>
      state.invoicingListSearchForm.updateNumberOfData(
        state.numberOfData.invoicing,
      ),
    receivedInvoicingListSearchForm: (state) =>
      state.receivedInvoicingListSearchForm.updateNumberOfData(
        state.numberOfData.receivedInvoicing,
      ),
    applicationListSearchForm: (state) =>
      state.applicationListSearchForm.updateNumberOfData(
        state.numberOfData.application,
      ),
    cashflowListSearchForm: (state) =>
      state.cashflowListSearchForm.updateNumberOfData(
        state.numberOfData.cashflow,
      ),
    employeeListSearchForm: (state) =>
      state.employeeListSearchForm.updateNumberOfData(
        state.numberOfData.employee,
      ),
    workflowListSearchForm: (state) =>
      state.workflowListSearchForm.updateNumberOfData(
        state.numberOfData.workflow,
      ),
    employeeDeleteSearchForm: (state) =>
      state.employeeDeleteSearchForm.updateNumberOfData(
        state.numberOfData.employeeDelete,
      ),
    fileDataListSearchForm: (state) =>
      state.fileDataListSearchForm.updateNumberOfData(
        state.numberOfData.fileData,
      ),
    fileDataUploadListSearchForm: (state) =>
      state.fileDataUploadConfirmSearchForm.updateNumberOfData(
        state.numberOfData.fileDataConfirm,
      ),
    newsListSearchForm: (state) =>
      state.newsListSearchForm.updateNumberOfData(state.numberOfData.news),
  },
};
