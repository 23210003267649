import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Date } from "@/model/values/date";
import { Status } from "@/model/values/status";

const properties = {
  id: 0,
  invoicingNumber: "",
  billingDate: Date.empty(),
  deadlineOn: Date.empty(),
  title: "",
  amountOfMoney: AmountOfMoney.empty(),
  status: Status.empty(),
  memo: "",
};

export class ReceivedInvoicingListItem {
  static valueOf(items = properties) {
    return new ReceivedInvoicingListItem(items);
  }
  static empty() {
    return new ReceivedInvoicingListItem({});
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }

  get isConverted() {
    return this.status.isConverted;
  }
}
