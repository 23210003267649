import { errorToMsg, parseErrorResponse } from "./convert_service";
import { moveErrorServiceBill } from "@/service/router_service";

export const customErrorHandleConfig = { customErrorHandle: true };

export const loginErrorHandle = (error) => {
  if (error.response) {
    const { status } = error.response;
    if (status === 401 || status === 403 || status === 405) {
      return { message: parseErrorResponse(error.response) };
    }
  }
  return errorToMsg(error);
};

export const homeErrorHandle = (error) => {
  if (error.response) {
    const { status } = error.response;
    if (status === 401) {
      return {
        message: parseErrorResponse(error.response),
        action: () => (location.href = "/login"),
      };
    }
    if (status === 403) {
      return "";
    }
    return parseErrorResponse(error.response);
  }
  return error;
};

export const authInfoErrorHandle = (error) => {
  if (error.response) {
    const { status } = error.response;
    if (status === 401) {
      const message = parseErrorResponse(error.response);
      return { message, action: () => (location.href = "/login") };
    }
    return parseErrorResponse(error.response);
  }
  return error;
};

export const employeeDeletedErrorHandle = () => {
  return {
    message: "この社員は削除されています",
    action: () => (location.href = "/home"),
  };
};

export const serviceBillErrorHandle = (error) => {
  if (error.response) {
    const { status } = error.response;
    if (status === 403) {
      // 他会員の請求の時
      return { action: () => moveErrorServiceBill() };
    }
  }
  return errorToMsg(error);
};
