import { format } from "@/service/date_service";
import dayjs from "@/dayjs";

export class YearMonth {
  constructor(id, value) {
    this.id = id;
    this.value = value;
  }

  static empty() {
    return YearMonth.valueOf(0, null);
  }

  static today() {
    return YearMonth.valueOf(0, dayjs());
  }
  static thisMonth() {
    const value = dayjs().startOf("month");
    return YearMonth.valueOf(0, value);
  }

  static prevMonth() {
    const value = dayjs().startOf("month").subtract(1, "month");
    return YearMonth.valueOf(0, value);
  }

  static valueOf(id, value) {
    return new YearMonth(id, dayjs.utc(value));
  }

  toString() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY年MM月");
  }

  get year() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY");
  }

  get month() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "MM");
  }

  get isExist() {
    return this.value.isValid();
  }

  toParams() {
    return { year: this.year, month: this.month };
  }
}
