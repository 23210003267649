import { AuthInfo } from "@/model/auth_info/auth_info";
import { employeeFromJsonAndCompany } from "@/dto/employee";
import { companyFromJson } from "@/dto/member";

export const authInfoFromJson = (json) => {
  const company = companyFromJson(json["company"]);
  const employee = employeeFromJsonAndCompany(company, json);
  return AuthInfo.valueOf({
    employee,
    company,
  });
};
