import { dummyWaitForLoading } from "@/infrastructure/dummy_repository_functions";
import { InformationListItem } from "@/model/information/information_list_item";
import { Date } from "@/model/values/date";

export class DummyInformationRepository {
  fetchInformationList = async () => {
    await dummyWaitForLoading();

    return [
      InformationListItem.valueOf({
        id: 2,
        message: "テスト2",
        isImportant: true,
        type: "application",
        postPeriodFrom: Date.valueOf("2021-06-01"),
        postPeriodTo: Date.valueOf("2021-06-01"),
        targetId: 1,
      }),
      InformationListItem.valueOf({
        id: 3,
        message: "テスト22",
        isImportant: false,
        type: "application",
        postPeriodFrom: Date.valueOf("2021-06-01"),
        postPeriodTo: Date.valueOf("2021-06-01"),
        targetId: 1,
      }),
      InformationListItem.valueOf({
        id: 4,
        message: "テスト332",
        isImportant: false,
        type: "application",
        postPeriodFrom: Date.valueOf("2021-06-01"),
        postPeriodTo: Date.valueOf("2021-06-01"),
        targetId: 1,
      }),
    ];
  };
}
