import { dummyWaitForLoading } from "@/infrastructure/dummy_repository_functions";
import { AccountInfo } from "@/model/account/account_info";
import { Account } from "@/model/values/account";
import { toCamel } from "@/service/convert_service";

export class DummyAccountRepository {
  fetchThisMonthAccountInfo = async () => {
    await dummyWaitForLoading(3000);
    const data = toCamel({
      date: "2021-06-02",
      balance: 1000,
      total_deposit: 3000,
      number_of_deposit: 4,
      total_not_payment_amount: 87656,
      number_of_not_payment: 9,
      total_withdrawal: 0,
      number_of_withdrawal: 0,
    });
    const {
      date,
      balance,
      totalDeposit,
      numberOfDeposit,
      totalNotPaymentAmount,
      numberOfNotPayment,
      totalWithdrawal,
      numberOfWithdrawal,
    } = data;
    return AccountInfo.valueOf({
      date,
      balance: Account.valueOf(balance),
      totalDeposit: Account.valueOf(totalDeposit),
      numberOfDeposit,
      totalNotPaymentAmount: Account.valueOf(totalNotPaymentAmount),
      numberOfNotPayment,
      totalWithdrawal: Account.valueOf(totalWithdrawal),
      numberOfWithdrawal,
    });
  };
}
