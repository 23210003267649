import { Balance } from "@/model/values/balance";
import { Date } from "@/model/values/date";
import { prefix } from "@/const/cash_flow";

const properties = {
  transactionAt: Date.empty(),
  toMemberName: "",
  statusJa: "",
  prefix: "",
  amount: Balance.empty(),
  balance: Balance.empty(),
  fee: Balance.empty(),
  type: "",
  recordCount: 0,
  fileId: "",
  fileType: "",
  paymentMemo: "",
};

export class CashflowListItem {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new CashflowListItem({ ...properties, ...items });
  }

  static empty() {
    return new CashflowListItem({});
  }

  get date() {
    return this.transactionAt.value;
  }

  get isPlus() {
    return this.prefix === prefix.plus.value;
  }

  get isMinus() {
    return this.prefix === prefix.minus.value;
  }
}
