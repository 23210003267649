import { status } from "@/const/status";
import { convertStatusName } from "@/service/status_service";

export class Status {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value = "") {
    return new Status(value);
  }
  static empty() {
    return Status.valueOf("");
  }

  updateStatus(value) {
    return value ? Status.valueOf(value) : Status.empty();
  }

  toString() {
    const { value } = this;
    return status[value] ? status[value].name : value;
  }

  statusName(authInfo, type) {
    return convertStatusName(authInfo, type, this.value);
  }

  get style() {
    const { value } = this;
    return status[value] ? status[value].style : "";
  }

  get isApplying() {
    return this.value === status.applying.value;
  }

  get isFirstApproval() {
    return this.value == status.first_approval.value;
  }
  get isRemand() {
    return this.value == status.remand.value;
  }
  get isWithdraw() {
    return this.value == status.withdraw.value;
  }
  get isRejected() {
    return this.value == status.rejected.value;
  }
  get isPaid() {
    return this.value == status.paid.value;
  }
  get isInvoiced() {
    return this.value == status.invoiced.value;
  }
  get isApproval() {
    return this.value == status.approval.value;
  }
  get isSecondApproval() {
    return this.value == status.second_approval.value;
  }
  get isGetBack() {
    return this.value == status.get_back.value;
  }
  get isCanceled() {
    return this.value == status.canceled.value;
  }

  get isConverted() {
    return this.value == status.payment_converted.value;
  }

  get isEmpty() {
    return !this.value;
  }

  get isChangeable() {
    return ![
      status.paid.value,
      status.payment_reservation.value,
      status.rejected.value,
      status.sending_error.value,
      status.withdraw.value,
      status.canceled.value,
    ].includes(this.value);
  }
}
