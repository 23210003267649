import { PermissionError } from "@/error/permission_error";
import { Payment } from "@/model/payment/payment";
import { Date } from "@/model/values/date";
import { clearJwt } from "./auth_service";
// import { externalTooltipHandler } from "./chart_service"
import { format } from "./date_service";
import { moveNotFound } from "./router_service";

// TODO: message以外も返すようになったので名前変更
export const errorToMsg = (err, config = {}) => {
  const { response } = err;
  if (!response) {
    return { message: err };
  }
  if (response.status == 403) {
    return {
      message: config.notDefaultMessage403
        ? parseErrorResponse(response)
        : new PermissionError(),
    };
  }
  if (response.status == 404) {
    return { action: () => moveNotFound() };
  }
  if (response.status == 401) {
    clearJwt();
    return {
      message: parseErrorResponse(response),
      action: () => (location.href = "/login"),
    };
  }

  return { message: parseErrorResponse(response) };
};

// TODO: 名前変更
export const errorToMsgNoAction = (err) => {
  const { response } = err;
  if (!response) {
    return err;
  }
  return parseErrorResponse(response);
};

export const parseErrorResponse = (response) => {
  if (!response.data) {
    return response.message;
  }
  const { errors } = response.data;
  if (!errors) return response.data;
  if (!Array.isArray(errors)) return errors;
  if (!errors.length) return errors;
  return errors[0].message;
};

export const toUnderscoreCase = (str) =>
  str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
export const toSnake = (obj) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    result[toUnderscoreCase(key)] = obj[key];
  });
  return result;
};

export const toCamelCase = (str) => {
  const fn = (word, index) => {
    if (index === 0) return word.toLowerCase();
    const first = word.charAt(0).toUpperCase();
    const other = word.slice(1).toLowerCase();
    return first + other;
  };
  const words = str.split("_").map((word, index) => fn(word, index));
  return words.join("");
};

export const toCamel = (obj) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    result[toCamelCase(key)] = obj[key];
  });
  return result;
};

export const convertToPayment = (receivedInvoicing) => {
  const { amountOfMoney, deadlineOn } = receivedInvoicing;

  const paymentDate = deadlineOn.gtToday ? deadlineOn : Date.today();
  return Payment.valueOf({
    amountOfMoney,
    paymentDate,
    receivedInvoicing: receivedInvoicing,
  });
};

export const convertToLineChartConfig = (unit, chartDataList) => {
  const labels = chartDataList.map(({ date }) => mapDateForUnit(date, unit));
  const borderColor = "rgba(252, 215, 27, 1)";
  const backgroundColor = "rgba(252, 215, 27, 0.1)";
  const gridColor = "#efefef";
  const datasets = [
    {
      fill: true,
      borderWidth: 2,
      borderColor,
      backgroundColor,
      data: chartDataList.map((e) => e.balance),
      pointRadius: chartDataList.length === 1 ? 6 : 0,
      pointHoverRadius: 6,
      pointHoverBorderWidth: 3,
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: borderColor,
    },
  ];
  // TODO: 動的なトコ以外はconstに移動
  return {
    type: "line",
    data: { labels, datasets },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltip: {
          backgroundColor: "#fff",
          titleColor: "#a0a0a0",
          bodyColor: "#2c2c2c",
          bodyFont: {
            weight: "bold",
          },
          borderWidth: 1,
          borderColor: gridColor,
          cornerRadius: 4,
          callbacks: {
            label: (tooltipItem) => `¥${tooltipItem.formattedValue}`,
          },
          displayColors: false,
          padding: 12,
        },
      },
      interaction: { mode: "index", intersect: false },
      scales: {
        x: {
          grid: {
            display: false,
          },
          offset: true,
        },
        y: {
          grid: {
            color: gridColor,
            drawBorder: false,
          },
          ticks: {
            callback: (tick) => `${(tick / 10000).toLocaleString()}万円`,
          },
          afterFit: (scale) => {
            scale.width = 80;
          },
          suggestedMin: 0,
        },
      },
    },
  };
};

const mapDateForUnit = (date, unit) => {
  switch (unit) {
    case "yearly":
      return format(date, "YYYY年");
    case "monthly":
      return format(date, "M月");
    default:
      return format(date, "M/D");
  }
};

export const convertToBarChartConfig = (unit, chartDataList) => {
  const labels = chartDataList.map(({ date }) => mapDateForUnit(date, unit));
  const barThickness = 20;
  const gridColor = "#efefef";
  const datasets = [
    {
      label: "入金",
      barThickness,
      data: chartDataList.map((e) => e.totalDeposit),
      backgroundColor: "#3BB8FF",
    },
    {
      label: "出金",
      barThickness,
      data: chartDataList.map((e) => parseInt(e.totalPayment * -1)),
      backgroundColor: "#FC6693",
    },
  ];
  // TODO: 動的なトコ以外はconstに移動
  return {
    type: "bar",
    data: { labels, datasets },
    options: {
      datasets: {
        bar: {
          borderRadius: 2,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltip: {
          backgroundColor: "#fff",
          titleColor: "#a0a0a0",
          bodyColor: "#2c2c2c",
          bodyFont: {
            weight: "bold",
          },
          borderWidth: 1,
          borderColor: gridColor,
          cornerRadius: 4,
          callbacks: {
            label: (tooltipItem) =>
              `${tooltipItem.dataset.label} ¥${tooltipItem.formattedValue}`,
          },
          displayColors: false,
          padding: 12,
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            color: gridColor,
            drawBorder: false,
          },
          stacked: true,
          ticks: {
            callback: (tick) =>
              tick === 0 ? tick : `${(tick / 10000).toLocaleString()}万円`,
          },
          afterFit: (scale) => {
            scale.width = 80;
          },
        },
      },
    },
  };
};

export const fullWidthCharToHalfWidthChar = (str) => {
  if (!str) return str;
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (char) =>
    String.fromCharCode(char.charCodeAt(0) - 65248),
  );
};
