// typescriptにしたい
const properties = {
  dataCount: 0,
  listItems: [],
};
export class ReceivedInvoicingList {
  static valueOf(items = {}) {
    return new ReceivedInvoicingList(items);
  }
  static empty() {
    return new ReceivedInvoicingList({});
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }
}
