import { Account } from "@/model/values/account";
import { Date } from "@/model/values/date";

const properties = {
  date: Date.today,
  balance: Account.empty(),
  totalDeposit: Account.empty(),
  totalPayment: Account.empty(),
  monthlyPayment: [],
};

export class AccountInfo {
  constructor(items = properties) {
    Object.assign(this, items);
  }
  static valueOf(items = properties) {
    return new AccountInfo({ ...properties, ...items });
  }

  static empty() {
    return AccountInfo.valueOf({});
  }
}
