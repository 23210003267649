import { DateRange } from "@/model/values/date_range";
import { toSnake } from "@/service/convert_service";
import { PageNation } from "@/model/form/page_nation";

const properties = {
  keyword: "",
  status: [],
  billingDate: DateRange.empty(),
  deadline: DateRange.empty(),
  pageNation: PageNation.firstPage(),
  sortDirection: "desc",
  sortColumn: "id",
};
export class InvoicingListSearchForm {
  constructor({
    keyword,
    status,
    billingDate,
    deadline,
    pageNation,
    sortColumn,
    sortDirection,
  }) {
    this.keyword = keyword;
    this.status = status;
    this.billingDate = billingDate;
    this.deadline = deadline;
    this.pageNation = pageNation;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static empty() {
    return InvoicingListSearchForm.valueOf(properties);
  }

  static valueOf(items = {}) {
    return new InvoicingListSearchForm({ ...properties, ...items });
  }

  clear() {
    const { pageNation } = this;
    return InvoicingListSearchForm.valueOf({
      ...properties,
      ...{ pageNation },
    });
  }

  updateKeyword(value) {
    return InvoicingListSearchForm.valueOf({ ...this, ...{ keyword: value } });
  }
  updateStatus(value) {
    return InvoicingListSearchForm.valueOf({ ...this, ...{ status: value } });
  }
  updateBillingDate(value) {
    const billingDate = this.billingDate.updateDateRange(value);
    return InvoicingListSearchForm.valueOf({ ...this, ...{ billingDate } });
  }
  updateDeadline(value) {
    const deadline = this.deadline.updateDateRange(value);
    return InvoicingListSearchForm.valueOf({ ...this, ...{ deadline } });
  }
  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return InvoicingListSearchForm.valueOf({ ...this, ...{ pageNation } });
  }
  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return InvoicingListSearchForm.valueOf({ ...this, ...{ pageNation } });
  }

  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return InvoicingListSearchForm.valueOf({ ...this, ...{ pageNation } });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return InvoicingListSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return InvoicingListSearchForm.valueOf({
      ...this,
      ...{ sortColumn: value },
    });
  }

  toJson() {
    const {
      status,
      keyword,
      billingDate,
      deadline,
      sortColumn,
      sortDirection,
    } = this;
    const params = this.pageNation.toJson();
    if (status.length) params.status = status;
    if (keyword) params.keyword = keyword;
    if (billingDate.isValid) {
      params.billing_date_from = billingDate.startDate.toString();
      params.billing_date_to = billingDate.endDate.toString();
    }
    if (deadline.isValid) {
      params.deadline_from = deadline.startDate.toString();
      params.deadline_to = deadline.endDate.toString();
    }
    params.sort_direction = sortDirection;
    params.sort_column = sortColumn;

    return toSnake(params);
  }
}
