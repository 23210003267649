export class Balance {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value) {
    return new Balance(value);
  }

  static empty() {
    return new Balance(0);
  }

  static copyWith(value) {
    return Balance.valueOf(value);
  }

  toString() {
    if (this.value === null) return "-";
    return `¥${this.value.toLocaleString()}`;
  }
}
