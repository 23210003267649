import { Balance } from "@/model/values/balance";
import { Date } from "@/model/values/date";

const properties = {
  date: Date.empty(),
  balance: Balance.empty(),
  totalDeposit: Balance.empty(),
  totalPayment: Balance.empty(),
};

export class ChartData {
  constructor({ date, balance, totalDeposit, totalPayment }) {
    this.date = date;
    this.balance = balance;
    this.totalDeposit = totalDeposit;
    this.totalPayment = totalPayment;
  }

  static valueOf(items = properties) {
    return new ChartData({ ...properties, ...items });
  }
  static empty() {
    return new ChartData({});
  }
}
