import { operationLogListItemFromJson } from "@/dto/operation_log";
import Repository from "@/infrastructure/api";
import { OperationLogList } from "@/model/operation_log/operation_log_list";

const resource = "member/operation-logs";
export class OperationLogRepository {
  fetchOperationLogList = async (params, config = {}) => {
    const { data } = await Repository.get(`${resource}`, {
      ...{ params },
      ...config,
    });
    const dataCount = data["data_count"];
    const currentOperationLogList = data["employee_list"];
    const listItems = currentOperationLogList.map((json) =>
      operationLogListItemFromJson(json),
    );

    return OperationLogList.valueOf({ dataCount, listItems });
  };
}
