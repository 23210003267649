// 社員編集表示用
// TODO: 後々はこちらに統一したい
export const displayFunctionPermissions = {
  self: [
    {
      id: "self_payment",
      name: "支払い",
      values: [
        { name: "登録", value: "payment_save_self" },
        { name: "変更", value: "payment_update_self" },
        { name: "削除", value: "payment_delete_self" },
        { name: "閲覧", value: "" },
      ],
    },
    // {
    //   id: "self_invoicing",
    //   name: "請求",
    //   values: [
    //     { name: "登録", value: "invoicing_save_self" },
    //     { name: "変更", value: "invoicing_update_self" },
    //     { name: "削除", value: "invoicing_delete_self" },
    //     { name: "閲覧", value: "" },
    //   ],
    // },
    {
      id: "self_balance",
      name: "入出金明細",
      values: [
        { name: "登録", value: "" },
        { name: "変更", value: "" },
        { name: "削除", value: "" },
        { name: "閲覧", value: "balance_read_self" },
      ],
    },
    {
      id: "self_operation_logs",
      name: "処理履歴",
      values: [
        { name: "登録", value: "" },
        { name: "変更", value: "" },
        { name: "削除", value: "" },
        { name: "閲覧", value: "operation-logs_read_self" },
      ],
    },
  ],
  other: [
    {
      id: "other_payment",
      name: "支払い",
      values: [
        { name: "登録", value: "" },
        { name: "変更", value: "payment_update_other" },
        { name: "削除", value: "payment_delete_other" },
        { name: "閲覧", value: "payment_read_other" },
        { name: "支払い", value: "" },
        { name: "差戻し", value: "" },
      ],
    },
    // {
    //   id: "other_invoicing",
    //   name: "請求",
    //   values: [
    //     { name: "登録", value: "" },
    //     { name: "変更", value: "invoicing_update_other" },
    //     { name: "削除", value: "invoicing_delete_other" },
    //     { name: "閲覧", value: "invoicing_read_other" },
    //     { name: "支払い", value: "" },
    //     { name: "差戻し", value: "" },
    //   ],
    // },
    // {
    //   id: "other_receive_invoicing",
    //   name: "受領請求",
    //   values: [
    //     { name: "登録", value: "" },
    //     { name: "変更", value: "" },
    //     { name: "削除", value: "" },
    //     { name: "閲覧", value: "receive-invoice_read_other" },
    //     { name: "支払い", value: "receive-invoice_payment_other" },
    //     { name: "差戻し", value: "receive-invoice_remand_other" },
    //   ],
    // },
    {
      id: "other_balance",
      name: "入出金明細",
      values: [
        { name: "登録", value: "" },
        { name: "変更", value: "" },
        { name: "削除", value: "" },
        { name: "閲覧", value: "balance_read_other" },
        { name: "支払い", value: "" },
        { name: "差戻し", value: "" },
      ],
    },
    {
      id: "other_operation_logs",
      name: "処理履歴",
      values: [
        { name: "登録", value: "" },
        { name: "変更", value: "" },
        { name: "削除", value: "" },
        { name: "閲覧", value: "operation-logs_read_other" },
        { name: "支払い", value: "" },
        { name: "差戻し", value: "" },
      ],
    },
  ],
  common: [
    {
      id: "common_employee",
      name: "社員情報",
      values: [
        { name: "登録", value: "employee_save" },
        { name: "変更", value: "employee_update" },
        { name: "削除", value: "employee_delete" },
        { name: "閲覧", value: "employee_read" },
      ],
    },
    {
      id: "common_business_associates",
      name: "取引先情報",
      values: [
        { name: "登録", value: "business-associates_save" },
        { name: "変更", value: "business-associates_update" },
        { name: "削除", value: "business-associates_delete" },
        { name: "閲覧", value: "business-associates_read" },
      ],
    },
    {
      id: "common_member",
      name: "会員情報",
      values: [
        { name: "登録", value: "" },
        { name: "変更", value: "member_update" },
        { name: "削除", value: "" },
        { name: "閲覧", value: "member_read" },
      ],
    },
  ],
};
