import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.setDefault("Asia/Tokyo");
dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);

export default dayjs;
