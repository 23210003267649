import { cashFlowListItemFromJson } from "@/dto/cashflow";
import Repository from "@/infrastructure/api";
import { BalanceTransition } from "@/model/balance/balance_transition";
import { CashflowList } from "@/model/balance/cashflow_list";
import { ChartData } from "@/model/chart/chart_data";
import { toCamel } from "@/service/convert_service";

const resource = "balance";
export class BalanceRepository {
  fetchCashflowList = async ({ year, month, params, config = {} }) => {
    const { data } = await Repository.get(
      `${resource}/transitions/${year}/${month}`,
      { ...{ params }, ...config },
    );
    const dataCount = data["data_count"];
    const currentBalanceTransitionList =
      data["current_balance_transition_list"];
    const listItems = currentBalanceTransitionList.map((json) =>
      cashFlowListItemFromJson(json),
    );
    return CashflowList.valueOf({ dataCount, listItems });
  };

  fetchBalanceTransition = async (unit, config = {}) => {
    const { data } = await Repository.get(
      `${resource}/history/${unit}`,
      config,
    );
    const chartDataList = data.map((json) => ChartData.valueOf(toCamel(json)));
    return BalanceTransition.valueOf({ unit, chartDataList });
  };

  updateBalanceTransition = async () => {
    const { data } = await Repository.put(resource);
    return data;
  };
}
