import Repository from "@/infrastructure/api";

const resource = "member";
export class MemberRepository {
  updateStatus = async (memberId) =>
    Repository.put(`${resource}/${memberId}/status`);
  updateSettings = async (payload) =>
    Repository.put(`${resource}/settings`, payload);
  sendQuestionnaire = async ({ memberId, payload }) =>
    Repository.post(`${resource}/${memberId}/questionnaire`, payload);
  updateStatus = async (memberId, payload) =>
    Repository.put(`${resource}/${memberId}/status`, payload);
}
