import { fileListItemFromJson } from "@/dto/file";
import Repository from "@/infrastructure/api";
import { FileDataList } from "@/model/file_data/file_data_list";

const resource = "payments";
export class PaymentRepository {
  fetchPaymentList = async (params) => {
    const { data } = await Repository.get(`${resource}`, { params });
    const dataCount = data["data_count"];
    const currentPaymentList = data["current_payment_list"];
    const listItems = currentPaymentList.map((json) =>
      fileListItemFromJson(json),
    );
    return FileDataList.valueOf({ dataCount, listItems });
  };

  register = async (payload) => await Repository.post(`${resource}`, payload);
  checkTransactionPassword = async (payload) => {
    await Repository.post(`${resource}/check_transaction_password`, payload);
  };
}
