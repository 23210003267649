export default {
  namespaced: true,
  state: {
    taskCount: 0,
  },
  mutations: {
    increment: (state) => state.taskCount++,
    decrement: (state) => state.taskCount--,
  },
  actions: {},
  getters: {
    loading: (state) => state.taskCount > 0,
  },
};
