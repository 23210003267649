import { AuthInfo } from "@/model/auth_info/auth_info";
import { hasAuthInfo } from "@/service/auth_service";
import {
  authInfoErrorHandle,
  customErrorHandleConfig,
} from "@/service/error_handle_service";

export class AuthInfoBehavior {
  constructor({ repository, authInfo }) {
    this.repository = repository;
    this.authInfo = authInfo;
  }

  static create({ repository }) {
    return new AuthInfoBehavior({
      repository,
      authInfo: AuthInfo.empty(),
    });
  }

  async initialize(authInfoOnStore) {
    if (hasAuthInfo(authInfoOnStore)) {
      this.authInfo = authInfoOnStore;
      return;
    }
    try {
      const config = customErrorHandleConfig;
      const data = await this.repository.fetchAuthInfo({}, config);
      this.authInfo = data;
    } catch (err) {
      throw authInfoErrorHandle(err);
    }
  }
}
