import { translate } from "./dictionary";
import { isMatch } from "@/service/validate_service";
import { checkTlToday, validate, isSameOrBefore } from "@/service/date_service";
import { email, max } from "vee-validate/dist/rules";
import { BT_MAXIMUM_AMOUNT } from "@/const/file_upload";

export default {
  alpha_num: {
    message: (name) => `${translate(name)}は半角英数字のみ使用できます`,
  },
  min: {
    // MEMO: 絵文字シーケンス非対応
    validate: (value, { length }) => [...value].length >= length,
    message: (name, { length }) => `${translate(name)}は${length}文字以上です`,
  },
  max: {
    // MEMO: 絵文字シーケンス非対応
    validate: (value, { length }) => [...value].length <= length,
    message: (name, { length }) => `${translate(name)}は${length}文字以下です`,
  },
  required: {
    message: (name) => `${translate(name)}を入力してください`,
  },
  email: {
    message: "有効なメールアドレスではありません",
  },
  kana_format: {
    validate: (value) => /^([ァ-ヴ][ァ-ヴー・]*)$/.test(value),
    message: `カナで入力してください`,
  },
  bank_kana_format: {
    validate: (value) =>
      /^[0-9０-９A-Za-zＡ-Ｚａ-ｚぁ-ろわをんゔァ-ロワヲンヴ（），ー．／ (),\\\-./ｦ-ｯｱ-ﾝﾞﾟｰ\x20\u3000]+$/.test(
        value,
      ),
    message: `使用できない文字が含まれています`,
  },
  lt_today: {
    validate: (value) => checkTlToday(value),
    message: `本日より過去の日付を入力してください`,
  },
  ge_today: {
    validate: (value) => !checkTlToday(value),
    message: (name) => `${translate(name)}は本日以降を指定してください`,
  },
  email_format: {
    ...email,
    message: "有効なメールアドレスではありません",
  },
  date_required: {
    validate: (value) => validate(value),
    message: (name) => `${translate(name)}を入力してください`,
  },
  amount_format: {
    validate: (value) => {
      return String(value)
        .replace(/,/g, "")
        .split("")
        .every((e) => !isNaN(parseInt(e), 10));
    },
    message: (name) => `${translate(name)}は数値で入力してください`,
  },
  not_allowed_zero_start: {
    validate: (value) => {
      return /^(?!0)[0-9]/.test(value.replace(/,/g, ""));
    },
    message: `0から始まる数値は無効です`,
  },
  password_format: {
    validate: ({ value }) =>
      /^(?=.*[!-/:-@[-`{-~])(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[!-~]{10,}$/.test(
        value,
      ),
    message:
      "半角英大文字、半角英小文字、半角数字、半角記号をそれぞれ1種類以上含む10文字以上必要です",
  },
  password_confirm: {
    params: ["target"],
    validate(value, { target }) {
      return isMatch(value, target);
    },
    message: "パスワードが一致しません",
  },
  transaction_password_format: {
    validate: (value) => /^(?=.*[\d])(?=.*[a-z])[\da-zA-Z]{8,20}$/.test(value),
    message: "半角英数字をそれぞれ1種類以上含む8文字以上必要です",
  },
  email_confirm: {
    params: ["target"],
    validate: (value, { target }) => {
      return value === target;
    },
    message: "メールアドレスが一致しません",
  },
  phone_number_format: {
    validate: (value) => {
      return /^0\d{10}$/.test(value);
    },
    message: "有効な電話番号ではありません",
  },
  corporate_phone_number_format: {
    validate: (value) => {
      return /^0\d{9,10}$/.test(value);
    },
    message: "有効な電話番号ではありません",
  },
  max_digits: {
    ...max,
    message: (name, { length }) => `${translate(name)}は${length}桁以下です`,
  },
  number_hyphen: {
    validate: (value) => {
      return /^[0-9]+$/.test(value);
    },
    message: "数字以外は入力しないでください",
  },
  max_amount: {
    validate: (value) => {
      return BT_MAXIMUM_AMOUNT >= Number(value.replace(/,/g, ""));
    },
    message: "送金可能金額を超えています",
  },
  approvalDeadline: {
    params: ["target"],
    validate: (value, { target }) => {
      return isSameOrBefore(value, target);
    },
    message: "支払日より後には指定できません",
  },
};
