import { status } from "@/const/employee_status";

export class EmployeeStatus {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value = "") {
    return new EmployeeStatus(value);
  }
  static empty() {
    return EmployeeStatus.valueOf();
  }

  toString() {
    const { value } = this;
    return status[value].name || "";
  }

  get isPending() {
    return this.value === status.registration_pending.value;
  }
  get isComplete() {
    return this.value === status.registration_completed.value;
  }
  get isDeleted() {
    return this.value === status.deleted.value;
  }
  get isLocked() {
    return this.value === status.account_locked.value;
  }
}
