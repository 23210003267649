import { PageNation } from "@/model/form/page_nation";
import { toSnake } from "@/service/convert_service";

const properties = {
  keyword: "",
  pageNation: PageNation.firstPage(),
  includesDeletedData: false,
  sortDirection: "asc",
  sortColumn: "id",
};

export class EmployeeListSearchForm {
  constructor({
    keyword,
    pageNation,
    includesDeletedData,
    sortColumn,
    sortDirection,
  }) {
    this.keyword = keyword;
    this.pageNation = pageNation;
    this.includesDeletedData = includesDeletedData;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static valueOf(items = properties) {
    return new EmployeeListSearchForm({ ...properties, ...items });
  }

  static empty() {
    return new EmployeeListSearchForm(properties);
  }

  clear() {
    const { pageNation } = this;
    return EmployeeListSearchForm.valueOf({ ...properties, ...{ pageNation } });
  }
  updateKeyword(value) {
    return new EmployeeListSearchForm({ ...this, ...{ keyword: value } });
  }
  updateDisabledFlag(value) {
    return new EmployeeListSearchForm({
      ...this,
      ...{ includesDeletedData: value },
    });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return new EmployeeListSearchForm({ ...this, ...{ pageNation } });
  }

  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return new EmployeeListSearchForm({ ...this, ...{ pageNation } });
  }
  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return new EmployeeListSearchForm({ ...this, ...{ pageNation } });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return EmployeeListSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return EmployeeListSearchForm.valueOf({
      ...this,
      ...{ sortColumn: value },
    });
  }

  toJson() {
    const {
      keyword,
      pageNation,
      includesDeletedData,
      sortColumn,
      sortDirection,
    } = this;
    const params = pageNation.toJson();
    params.includesDeletedData = includesDeletedData;
    params.sort_direction = sortDirection;
    params.sort_column = sortColumn;

    if (keyword) params.keyword = keyword;

    return toSnake(params);
  }
}
