export default {
  namespaced: true,
  state: {
    fileTitle: "",
    fileData: null,
  },
  mutations: {
    updateFileTitle: (state, payload) => (state.fileTitle = payload),
    updateFile: (state, payload) => (state.fileData = payload),
  },
  actions: {},
  getters: {
    fileTitle: (state) => state.fileTitle,
    fileData: (state) => state.fileData,
  },
};
