const dictionary = {
  applicationNumber: "申込番号",
  billingDate: "請求日",
  companyName: "会社名",
  companyPhoneNumber: "代表電話番号",
  email: "メールアドレス",
  userAddress: "メールアドレス",
  userAddressConfirm: "メールアドレス (確認)",
  mailAddress: "メールアドレス",
  firstName: "名",
  firstNameKana: "メイ",
  loginIdOrMailAddress: "ログインID",
  middleName: "姓",
  lastName: "姓",
  birthday: "生年月日",
  middleNameKana: "セイ",
  password: "パスワード",
  passwordConfirm: "パスワード（確認）",
  paymentMemo: "支払メモ",
  paymentComment: "申請コメント",
  mailAddressConfirm: "新しいメールアドレス（確認）",
  invitationMailAddressConfirm: "メールアドレス（確認）",
  phoneNumber: "携帯電話番号",
  clientSpecifiedId: "支払番号",
  paymentName: "支払件名",
  paymentDate: "支払日",
  payment: "支払金額",
  payeeCompany: "支払先",
  requestNumber: "請求番号",
  requestCompany: "請求先",
  requestSubject: "請求件名",
  requestMoney: "請求金額",
  requestDeadline: "支払期限",
  requestMemo: "請求メモ",
  requestComment: "申請コメント",
  approvalComment: "承認コメント",
  applyOrApprovalComment: "申請/承認コメント",
  employeeNumber: "社員番号（任意の識別番号）",
  approvalDeadline: "承認期限",
  fileTitle: "ファイル名",
  fileName: "支払件名",
  bank: "銀行",
  branch: "支店",
  accountType: "預金種別",
  accountNumber: "口座番号",
  recipientNameKana: "口座名義",
  accountNameKana: "口座名義カナ",
  freeSearch: "フリーワード",
  address: "住所",
};

export const translate = (name) => {
  const field = dictionary[name];
  return field || name;
};
