import { isExistCheck } from "@/service/validate_service";
import { extend, validate, localize } from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import * as presetRules from "vee-validate/dist/rules";
import customRules from "./custom_rules";

const rules = {};
const initialize = () => {
  localize("ja", ja);

  Object.keys(presetRules).forEach((rule) => {
    extend(rule, presetRules[rule]);
  });
  Object.keys(customRules).forEach((rule) => {
    extend(rule, customRules[rule]);
  });
  extend("if_exist", {
    params: ["rule"],
    validate: async (value, { rule }) => {
      if (!isExistCheck(value)) return true;
      const { valid } = await validate(value, rule);
      return valid;
    },
    message: (_, { rule }) => {
      const { message } = customRules[rule]; // TODO: RulesContainerからとる
      return message;
    },
  });
};
rules.initialize = initialize;
export default rules;
