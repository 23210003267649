import { Role } from "@/model/values/role";

export class Approver extends Role {
  constructor(value) {
    super(value);
  }

  static valueOf(value) {
    return new Approver(value);
  }
  static empty() {
    return new Approver(null);
  }

  get isFirstApprover() {
    return false;
  }
}
