import { GanbNotActivatedError } from "@/error/ganb_not_activated_error";
import { PermissionError } from "@/error/permission_error";
import { UnauthorizedError } from "@/error/unauthorize_error";
import { authenticate } from "@/service/auth_service";
import {
  deletePermissionCheck,
  readPermissionCheck,
  registerPermissionCheck,
  updatePermissionCheck,
} from "@/service/permission_service";
import store from "@/store";

export const tokenGuard = (to) =>
  !to.path === "/login-register" || to.query.token;

export const loginRegisterGuard = (to, from) =>
  !to.path === "/login-register" || from.path === "/sms-authenticate";

export const applicationGuard = (to) => {
  if (to.path.startsWith("/application")) {
    return store.getters["auth/isUseWorkflow"];
  }
  return true;
};

export const applicationEditGuard = (to, from) => {
  if (/.*detail/.test(from.path)) {
    if (/.*edit/.test(to.path)) return;
    else store.commit("application/clear");
  }
};

// TODO: リファクタ, ErrorじゃなくてBooleanを返すべき
export const readPermissionGuard = (type) => {
  const { functionPermissions } = store.state.auth.authInfo;
  const result = readPermissionCheck(type, functionPermissions);
  if (result) return null;

  return new PermissionError();
};

export const registerPermissionGuard = (type) => {
  const { isUseWorkflow, role, functionPermissions } =
    store.state.auth.authInfo;
  const result = registerPermissionCheck(type, functionPermissions);
  if (!isUseWorkflow && result) return null;
  if (isUseWorkflow && result && role.isApplicant) return null;

  return new PermissionError();
};

export const registerPermissionGuardIgnoreRole = (type) => {
  const { functionPermissions } = store.state.auth.authInfo;
  const result = registerPermissionCheck(type, functionPermissions);
  if (result) return null;

  return new PermissionError();
};

export const updatePermissionGuard = (type) => {
  const { isUseWorkflow, role, functionPermissions } =
    store.state.auth.authInfo;
  const result = updatePermissionCheck(type, functionPermissions);
  // TODO: 条件を精査
  if (!isUseWorkflow && result) return null;
  if (isUseWorkflow && result && role !== null) return null;

  return new PermissionError();
};

export const deletePermissionGuard = (type) => {
  const { functionPermissions } = store.state.auth.authInfo;
  const result = deletePermissionCheck(type, functionPermissions);
  if (result) return null;

  return new PermissionError();
};

export const isUseWorkflowGuard = () => {
  const { authInfo } = store.state.auth;
  if (authInfo.isUseWorkflow) return null;

  return new PermissionError();
};

export const isPrimaryUserGuard = () => {
  const { authInfo } = store.state.auth;
  if (authInfo.employee.isPrimaryUser) return null;

  return new PermissionError();
};

export const authGuard = () => {
  if (authenticate()) return null;

  return new UnauthorizedError();
};

export const tryPermissionGuard = (to) => {
  const { guard } = to.meta;
  if (guard.length) {
    const errors = guard.map((f) => f()).filter((v) => v);
    if (errors.length) {
      throw errors[0];
    }
  }
};

export const tryAuthenticateGuard = (to) => {
  const { guard } = to.meta;
  if (guard.length) {
    const errors = guard.map((f) => f()).filter((v) => v);
    if (errors.length) {
      throw errors[0];
    }
  }
};

export const ganbGuard = () => {
  const { authInfo } = store.state.auth;
  if (authInfo.company.ganbStatus === "activated") return null;

  return new GanbNotActivatedError();
};
