import { PageNation } from "@/model/form/page_nation";
import { DateRange } from "@/model/values/date_range";
import { toSnake } from "@/service/convert_service";

const properties = {
  keyword: "",
  status: [],
  billingDate: DateRange.empty(),
  deadline: DateRange.empty(),
  pageNation: PageNation.firstPage(),
  sortDirection: "desc",
  sortColumn: "id",
};

export class ReceivedInvoicingListSearchForm {
  constructor({
    keyword,
    status,
    billingDate,
    deadline,
    pageNation,
    sortColumn,
    sortDirection,
  }) {
    this.keyword = keyword;
    this.status = status;
    this.billingDate = billingDate;
    this.deadline = deadline;
    this.pageNation = pageNation;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static valueOf(items = {}) {
    return new ReceivedInvoicingListSearchForm({ ...properties, ...items });
  }

  static empty() {
    return ReceivedInvoicingListSearchForm.valueOf(properties);
  }

  clear() {
    const { pageNation } = this;
    return ReceivedInvoicingListSearchForm.valueOf({
      ...properties,
      ...{ pageNation },
    });
  }

  updateKeyword(value) {
    return ReceivedInvoicingListSearchForm.valueOf({
      ...this,
      ...{ keyword: value },
    });
  }
  updateStatus(value) {
    return ReceivedInvoicingListSearchForm.valueOf({
      ...this,
      ...{ status: value },
    });
  }
  updateBillingDate(value) {
    const billingDate = this.billingDate.updateDateRange(value);
    return ReceivedInvoicingListSearchForm.valueOf({
      ...this,
      ...{ billingDate },
    });
  }
  updateDeadline(value) {
    const deadline = this.deadline.updateDateRange(value);
    return ReceivedInvoicingListSearchForm.valueOf({
      ...this,
      ...{ deadline },
    });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return ReceivedInvoicingListSearchForm.valueOf({
      ...this,
      ...{ pageNation },
    });
  }
  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return ReceivedInvoicingListSearchForm.valueOf({
      ...this,
      ...{ pageNation },
    });
  }

  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return ReceivedInvoicingListSearchForm.valueOf({
      ...this,
      ...{ pageNation },
    });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return ReceivedInvoicingListSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return ReceivedInvoicingListSearchForm.valueOf({
      ...this,
      ...{ sortColumn: value },
    });
  }

  toJson() {
    // TODO: add billingDate, deadline
    const { status, keyword, pageNation, sortColumn, sortDirection } = this;
    const params = pageNation.toJson();
    if (status.length) params.status = status;
    if (keyword) params.keyword = keyword;
    // if (billingDate.isValid) {
    //   params.billingDate = billingDate.start.toParam()
    //   params.billingDate = billingDate.end.toParam()
    // }
    // if (deadline.isValid) {
    //   params.deadlineOn = deadline.start.toParam()
    //   params.deadlineOn = deadline.end.toParam()
    // }

    params.sort_direction = sortDirection;
    params.sort_column = sortColumn;

    return toSnake(params);
  }
}
