export class SmsAuthenticateCode {
  constructor({ id, value }) {
    this.id = id;
    this.value = value;
  }

  static empty() {
    return SmsAuthenticateCode.valueOf({});
  }
  static valueOf({ id = 0, value = "" }) {
    return new SmsAuthenticateCode({ id, value });
  }

  // TODO: behaviorでvalidateするべきかも
  updateCode(value) {
    const match = /[0-9]/.test(value);
    if (value < 0 || value > 9 || !match) {
      return SmsAuthenticateCode.valueOf({
        id: this.id,
        value: "",
      });
    }
    return SmsAuthenticateCode.valueOf({
      id: this.id,
      value: String(value),
    });
  }

  toString() {
    return this.value;
  }
}
