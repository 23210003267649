import { clientFromJson } from "@/dto/oauth";
import Repository from "@/infrastructure/api";

export class OAuthRepository {
  fetchClient = async (params, config = {}) => {
    const { data } = await Repository.get("oauth/client", {
      ...{ params },
      ...config,
    });
    return clientFromJson(data);
  };

  issueAuthorizationCode = async (payload, jwt) => {
    const { data } = await Repository.post(
      "oauth/authorization_code",
      payload,
      { headers: { Authorization: `Bearer ${jwt}` } },
    );
    return data;
  };
}
