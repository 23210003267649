import { dummyWaitForLoading } from "@/infrastructure/dummy_repository_functions";
import { RegisterAuth } from "@/model/auth_info/register_auth";
import { toCamel } from "@/service/convert_service";

export class DummyInvitationRepository {
  invitationMember = async (payload) => {
    console.log(`payload is ${JSON.stringify(payload)}`);
    await dummyWaitForLoading();
  };
  invitationEmployee = async (payload) => {
    console.log(`payload is ${JSON.stringify(payload)}`);
    await dummyWaitForLoading();
  };

  authenticateToken = async (payload) => {
    console.log(`payload is ${JSON.stringify(payload)}`);
    await dummyWaitForLoading(0);
    const data = toCamel({
      member_id: 1,
      employee_id: 1,
      email: "aaaaaaa@example.com",
      jwt_token:
        "eyJhbGciOiJSUzI1NiJ9.eyJpZCI6MSwiaXNzIjoicmFrdXRhc3VfcGx1c19hcGkiLCJleHAiOjE2MjY5MjA1MzZ9.YmQB8Q5vA-ksTQNxU3WrccY_uDNXHWQJgcO47KsPrpab1YLyQTryhg8vtvWact23Gx0dETE8rl2q9tmFvIyoGiQMwASHfkLUrcRE6aXo7Fj9S8PFWzpCiKkCD6f7kVU-Q6yIqkAPLWD0geb_VwxPhmXeivCfIpRPijO0hX2U49o",
    });
    return RegisterAuth.valueOf(data);
  };
}
