import Vue from "vue";

const loadConfig = {
  canCancel: false,
};

export const handlePromise = (command, callback) => {
  command()
    .then(() => callback())
    .catch(({ message, action }) => {
      if (message) alert(message);
      if (action) action();
    });
};

// TODO: storeにしてもいいかも
export const handleRequest = async (command, callback, reload = false) => {
  const loader = Vue.$loading.show(loadConfig);
  await command()
    .then(() => callback())
    .catch(({ message, action }) => {
      if (reload) loader.hide();
      if (message) alert(message);
      if (action) action();
    })
    .finally(() => {
      if (!reload) loader.hide();
    });
};
