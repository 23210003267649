import { convertToLineChartConfig } from "@/service/convert_service";
import { convertToBarChartConfig } from "@/service/convert_service";

export class BalanceTransition {
  constructor({ unit, chartDataList }) {
    this.unit = unit;
    this.chartDataList = chartDataList;
  }

  static empty() {
    return BalanceTransition.valueOf({});
  }

  static valueOf({ unit, chartDataList = [] }) {
    return new BalanceTransition({ unit, chartDataList });
  }

  get labels() {
    return this.chartDataList.map((e) => e.date.toParam());
  }
  get lineChartConfig() {
    return convertToLineChartConfig(this.unit, this.chartDataList);
  }
  get barChartConfig() {
    return convertToBarChartConfig(this.unit, this.chartDataList);
  }
}
