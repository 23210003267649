import Repository from "@/infrastructure/api";
import { EmployeeList } from "@/model/employee/employee_list";
import { employeeFromJson, employeeListItemFromJson } from "@/dto/employee";

const resource = "employees";
export class EmployeeRepository {
  fetchEmployeeList = async (params, config = {}) => {
    const { data } = await Repository.get(`${resource}`, {
      ...{ params },
      ...config,
    });
    const dataCount = data["data_count"];
    const currentEmployeeList = data["employee_list"];
    const listItems = currentEmployeeList.map((json) =>
      employeeListItemFromJson(json),
    );
    return EmployeeList.valueOf({ dataCount, listItems });
  };

  fetchEmployee = async (employeeId) => {
    const { data } = await Repository.get(`${resource}/${employeeId}`);
    return employeeFromJson(data);
  };

  post = async (payload) => await Repository.post(`${resource}`, payload);
  show = async (employeeId) =>
    await Repository.get(`${resource}/${employeeId}`);
  update = async ({ employeeId, payload, headers = {} }) =>
    await Repository.put(`${resource}/${employeeId}`, payload, {
      headers,
    });
  passwordReset = async (payload) =>
    await Repository.put(`${resource}/password`, payload);
  updateEmail = async (payload) =>
    await Repository.put(`${resource}/email`, payload);
  delete = async (employeeId) =>
    await Repository.delete(`${resource}/${employeeId}`);
  updateSettings = async ({ employeeId, payload }) =>
    await Repository.put(`${resource}/${employeeId}/settings`, payload);
  unlock = async (id) => await Repository.post(`${resource}/${id}/unlock`);
  updateTransactionPassword = async (value, config) =>
    await Repository.put(`${resource}/transaction_password`, value, config);
}
