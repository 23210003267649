import { PageNation } from "@/model/form/page_nation";
import { YearMonth } from "@/model/values/year_month";

const properties = {
  yearMonth: YearMonth.today(),
  pageNation: PageNation.firstPage(),
  sortDirection: "desc",
  sortColumn: "id",
};

export class CashflowListSearchForm {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new CashflowListSearchForm({ ...properties, ...items });
  }
  static empty() {
    return CashflowListSearchForm.valueOf();
  }

  clear() {
    const { pageNation } = this;
    return CashflowListSearchForm.valueOf({ ...properties, ...{ pageNation } });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return CashflowListSearchForm.valueOf({ ...this, pageNation });
  }
  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return CashflowListSearchForm.valueOf({ ...this, pageNation });
  }
  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return CashflowListSearchForm.valueOf({ ...this, pageNation });
  }

  updateYearMonth(date) {
    const { id, value } = date;
    const yearMonth = YearMonth.valueOf(id, value);
    return CashflowListSearchForm.valueOf({ ...this, yearMonth });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return CashflowListSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return CashflowListSearchForm.valueOf({
      ...this,
      ...{ sortColumn: value },
    });
  }

  toJson() {
    const { year, month } = this.yearMonth;
    const params = this.pageNation.toJson();

    params.sort_direction = this.sortDirection;
    params.sort_column = this.sortColumn;

    return { year, month, params };
  }
}
