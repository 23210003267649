import { accountType } from "@/const/file_upload";
import { Date } from "@/model/values/date";
import { AmountOfMoney } from "../values/amount_of_money";
import { Status } from "../values/status";

const properties = {
  id: 0,
  uploadId: "",
  paymentId: "",
  paymentDate: Date.empty(),
  uploadDate: Date.empty(),
  recipientName: "",
  fileName: "",
  recordCount: 0,
  amountOfMoney: AmountOfMoney.empty(),
  fee: AmountOfMoney.empty(),
  status: Status.empty(),
  clientSpecifiedId: "",
  fileType: "",
  bankCode: "",
  branchName: "",
  accountType: accountType[0],
  accountNumber: "",
  errorReason: "",
};

export class FileDataListItem {
  static valueOf(items = properties) {
    return new FileDataListItem({ ...properties, ...items });
  }
  static empty() {
    return new FileDataListItem();
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }
}
