import {
  invoicingDetailFromJson,
  invoicingEditFromJson,
  invoicingListItemFromJson,
  serviceBillFromJson,
} from "@/dto/invoicing";
import Repository from "@/infrastructure/api";
import { InvoicingList } from "@/model/invoicing/invoicing_list";

const resource = "invoicings";
export class InvoicingRepository {
  fetchInvoicingList = async (params) => {
    const { data } = await Repository.get(`${resource}`, { params });
    const dataCount = data["data_count"];
    const currentInvoicingList = data["current_invoicing_list"];
    const listItems = currentInvoicingList.map((json) =>
      invoicingListItemFromJson(json),
    );
    return InvoicingList.valueOf({ dataCount, listItems });
  };

  // 請求登録
  register = async (payload) => await Repository.post(`${resource}`, payload);
  // 請求更新
  update = async (payload) => await Repository.put(`${resource}`, payload);
  delete = async (invoicingId) =>
    await Repository.delete(`${resource}/${invoicingId}`);
  // 請求詳細
  fetchInvoicing = async (invoicingId) => {
    const { data } = await Repository.get(`${resource}/${invoicingId}`);
    return invoicingDetailFromJson(data);
  };
  // 請求変更
  fetchInvoicingEdit = async (invoicingId) => {
    const { data } = await Repository.get(`${resource}/${invoicingId}`);
    return invoicingEditFromJson(data);
  };

  fetchServiceBill = async (serviceBillId, config = {}) => {
    const { data } = await Repository.get(`service_bills/${serviceBillId}`, {
      ...config,
    });
    return serviceBillFromJson(data);
  };
}
