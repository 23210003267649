import { ReceivedInvoicingList } from "@/model/received_invoicing/received_invoicing_list";
import { ReceivedInvoicingListItem } from "@/model/received_invoicing/received_invoicing_list_item";
import { AmountOfMoney } from "@/model/values/amount_of_money";

const dummyWaitForLoading = async (time = 2000) =>
  await new Promise((resolve) => setTimeout(resolve, time));

export class DummyReceivedInvoicingRepository {
  fetchReceivedInvoicingList = async (params) => {
    console.log(`params is ${params}`);
    await dummyWaitForLoading();

    const listItems = [
      ReceivedInvoicingListItem.valueOf({
        id: 1,
        invoicingNumber: "000120100111",
        paymentDate: "2021/1/31",
        deadlineOn: "2021/2/1",
        billingDate: "2021/2/5",
        title: "12月ホスティングサービス利用分",
        amountOfMoney: AmountOfMoney.valueOf(14500000),
        status: "支払作成済",
      }),
      ReceivedInvoicingListItem.valueOf({
        id: 2,
        invoicingNumber: "000120100111",
        paymentDate: "2021/3/25",
        deadlineOn: "2021/2/1",
        billingDate: "2021/2/5",
        title: "12月ホスティングサービス利用分",
        amountOfMoney: AmountOfMoney.valueOf(30000),
        status: "支払作成済",
      }),
    ];

    return ReceivedInvoicingList.valueOf({
      dataCount: listItems.length,
      listItems,
    });
  };
}
