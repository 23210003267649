import { baseURL } from "@/config";
import { PermissionError } from "@/error/permission_error";
import store from "@/store";
import axios from "axios";

const csrfToken =
  process.env.NODE_ENV === "test"
    ? ""
    : document.querySelector('meta[name="csrf-token"]').getAttribute("content");

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": csrfToken,
    Accept: `application/vnd.rakutasu.v${process.env.RAILS_API_VERSION}`,
  },
  customErrorHandle: false,
  timeout: 60 * 1000,
});

api.interceptors.request.use(
  (request) => {
    request.headers = newHeader(request);
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const isTimeout = error.code === "ECONNABORTED";
    if (isTimeout) {
      return Promise.reject("タイムアウトが発生しました");
    }
    if (!error.response) {
      return Promise.reject("サーバーと通信できません");
    }
    if (error.config.customErrorHandle) {
      return Promise.reject(error);
    }
    if (error.response.status === 403) {
      return Promise.reject(new PermissionError());
    }
    return Promise.reject(error);
  },
);

const newHeader = (request) => {
  let newHeader = { ...request.headers };
  const { jwt } = store.state.auth;
  if (jwt) {
    newHeader = {
      ...newHeader,
      ...{ Authorization: `Bearer ${jwt}` },
    };
  }

  if (!newHeader["Content-Type"]) {
    newHeader = {
      ...newHeader,
      ...{ "Content-Type": "application/json" },
    };
  }

  return newHeader;
};
export default api;
