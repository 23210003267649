import { Role } from "@/model/values/role";

export class FirstApprover extends Role {
  constructor(value) {
    super(value);
  }

  static valueOf(value) {
    return new FirstApprover(value);
  }
  static empty() {
    return new FirstApprover(null);
  }

  get isApprover() {
    return false;
  }
}
