export class FunctionPermission {
  constructor(value) {
    this.value = value;
  }

  static empty() {
    return FunctionPermission.valueOf(null);
  }

  static valueOf(value) {
    return new FunctionPermission(value);
  }

  startsWith(str) {
    return this.value.startsWith(str);
  }

  endsWith(str) {
    return this.value.endsWith(str);
  }
}
