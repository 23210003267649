// typescriptにしたい
const properties = {
  dataCount: 0,
  listItems: [],
};
export class InvoicingList {
  static valueOf(items = {}) {
    return new InvoicingList(items);
  }
  static empty() {
    return new InvoicingList({});
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }
}
