import Repository from "@/infrastructure/api";
import { receivedInvoicingListItemFromJson } from "@/dto/received_invoicing";
import { ReceivedInvoicingList } from "@/model/received_invoicing/received_invoicing_list";

const resource = "receive-invoicings";
const resourcePayment = "payments";
export class ReceivedInvoicingRepository {
  fetchReceivedInvoicingList = async (params) => {
    const { data } = await Repository.get(`${resource}`, { params });
    const dataCount = data["data_count"];
    const currentInvoicingList = data["current_invoicing_list"];
    const listItems = currentInvoicingList.map((json) =>
      receivedInvoicingListItemFromJson(json),
    );
    return ReceivedInvoicingList.valueOf({ dataCount, listItems });
  };
  register = async (payload) => {
    await Repository.get(`${resourcePayment}`, payload);
  };
}
