export const applicationCompleteModalMessages = (type) => {
  const opName = type.isPayment ? "支払い" : "請求";
  return {
    applying: {
      title: `${opName}申請完了`,
      message: `この${opName}を申請しました`,
    },
    withdraw: {
      title: "取り下げました",
      message: `この内容の${opName}を取り下げました`,
    },
    get_back: {
      title: "取り戻しました",
      message: `この内容の${opName}を取り戻しました`,
    },
    rejected: {
      title: "否認しました",
      message: `この内容の${opName}を否認しました`,
    },
    remand: {
      title: "差し戻しました",
      message: `この内容の${opName}を差し戻しました`,
    },
    approval: {
      title: "承認しました",
      message: `この内容で${opName}を承認しました`,
    },
    first_approval: {
      title: "承認しました",
      message: `この内容で${opName}を承認しました`,
    },
    second_approval: {
      title: "承認しました",
      message: `この内容で${opName}を承認しました`,
    },
    canceled: {
      title: "取り消しました",
      message: `この内容の${opName}を取り消しました`,
    },
  };
};
