import { roles } from "@/const/workflow_roles";

// 詳しい判定はRoleを継承してるやつを参照してください
export class Role {
  constructor(value) {
    this.value = value;
  }

  static empty() {
    return Role.valueOf(null);
  }

  static valueOf(value) {
    return new Role(value);
  }

  get isApplicant() {
    return this.value === roles.applicant.value;
  }
  get isApprover() {
    return this.value === roles.approver.value;
  }
  get isFirstApprover() {
    return this.value === roles.approver.value;
  }
  get isSecondApprover() {
    return this.value === roles.second_approver.value;
  }

  toString() {
    const { value } = this;
    if (!this.value) return "-";
    return roles[value] ? roles[value].name : value;
  }

  equals(obj) {
    if (obj instanceof Role) {
      return obj.value === this.value;
    }
    return false;
  }
}
