export const prefix = {
  plus: { value: "plus", name: "入金" },
  minus: { value: "minus", name: "支払い" },
};

export const cashFlowType = {
  bulk_sending: "支払",
  capital_deposit: "チャージ",
  charge_back: "口座への戻し",
  resemble: "組戻し",
  unable_to_transfer: "振込不能",
  refund_resemble_commission: "組戻手数料の返金",
  recieve_sending: "入金",
};
