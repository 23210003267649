import { clearJwt } from "@/service/auth_service";
import store from "@/store";

export const noAuthFlow = (to, next) => {
  if (to.name !== "Terms" && to.name !== "Faq") authClear();

  if (to.meta && to.meta.authRequired) {
    const query = { redirect: to.fullPath };
    next({ path: "/login", replace: true, query });
  } else next();
};

const authClear = () => {
  clearJwt();
  store.commit("auth/clearJwt");
  store.commit("auth/clearAuthInfo");
};
