const properties = {
  accountNumber: "",
  accountType: "",
  bankCode: "",
  branchCode: "",
  recipientNameKana: "",
};

export class BankAccount {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    return new BankAccount({ ...properties, ...item });
  }

  static empty() {
    return BankAccount.valueOf({});
  }
}
