import { ReceivedInvoicingListItem } from "@/model/received_invoicing/received_invoicing_list_item";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { ApplicationType } from "@/model/values/application_type";
import { Date } from "@/model/values/date";
import { Status } from "@/model/values/status";
import { toCamel } from "@/service/convert_service";
import { Application } from "@/model/application/application";

export const receivedInvoicingListItemFromJson = (json) => {
  const {
    invoicingId,
    invoicingNumber,
    billingDate,
    title,
    amountOfMoney,
    status,
    memo,
    deadlineOn,
  } = toCamel(json);

  const type = ApplicationType.payment();
  const application = Application.valueOf({
    type,
    status: Status.valueOf(status),
  });
  return ReceivedInvoicingListItem.valueOf({
    id: invoicingId,
    billingDate: Date.valueOf(billingDate),
    amountOfMoney: AmountOfMoney.valueOf(amountOfMoney),
    status: Status.valueOf(status),
    deadlineOn: Date.valueOf(deadlineOn),
    application,
    invoicingNumber,
    memo,
    title,
  });
};
