const genBaseDomain = () => {
  if (process.env.NODE_ENV !== "test") {
    console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
    console.log(`RAILS_ENV: ${process.env.RAILS_ENV}`);
  }
  if (process.env.RAILS_ENV === "production")
    return "api.rakutasu-plus.com/api";
  if (process.env.RAILS_ENV === "development")
    return "development.api.rakutasu-plus.com/api";
  return "localhost:3001/api";
};
const genProtocol = () => {
  if (process.env.RAILS_ENV === "production") return "https";
  if (process.env.RAILS_ENV === "development") return "https";
  return "http";
};

const baseDomain = genBaseDomain();
const protocol = genProtocol();

export const baseURL = `${protocol}://${baseDomain}`;
