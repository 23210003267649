const properties = {
  dataCount: 0,
  listItems: [],
};

export class ApplicationList {
  static valueOf(items = {}) {
    return new ApplicationList(items);
  }
  static empty() {
    return new ApplicationList({});
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }
}
