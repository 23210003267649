import Repository from "@/infrastructure/api";

const resource = "sms/phone-number";
export class SmsRepository {
  issueSmsAuthentication = async (params, headers = {}) => {
    await Repository.post(`${resource}/new/send-passcode`, params, { headers });
  };
  // TODO: 名前がよくないので変更
  issueUpdateSmsAuthentication = async (params) => {
    await Repository.post(`${resource}/update/send-passcode`, params);
  };
  smsAuthenticate = async (payload) => {
    await Repository.put(`${resource}/auth`, payload);
  };
  smsAuthenticateWithUpdatePhoneNumber = async (payload, headers = {}) => {
    await Repository.put(`${resource}/update`, payload, { headers });
  };
}
