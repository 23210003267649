import { format } from "@/service/date_service";
import { Date } from "./date";

export class DateRange {
  constructor({ start, end }) {
    this.start = start;
    this.end = end;
  }

  static empty() {
    return DateRange.valueOf({});
  }

  static valueOf({ start = Date.empty(), end = Date.empty() }) {
    return new DateRange({ start, end });
  }
  static copyWith({ start, end }) {
    return new DateRange({
      start: Date.valueOf(start),
      end: Date.valueOf(end),
    });
  }

  updateDateRange(value) {
    const { start, end } = value;
    const values = [Date.valueOf(start), Date.valueOf(end)].sort(
      (a, b) => a.value?.toDate() - b.value?.toDate(),
    );

    return DateRange.valueOf({
      start: values[0],
      end: values[1],
    });
  }

  get isValid() {
    const { start, end } = this;
    return start.isExist && end.isExist;
  }

  get startDate() {
    const { start } = this;
    return format(start, "YYYY-MM-DD");
  }

  get endDate() {
    const { end } = this;
    return format(end, "YYYY-MM-DD");
  }

  get range() {
    const { start, end } = this;
    return { start: start.value?.toDate(), end: end.value?.toDate() };
  }

  toString() {
    const { start, end } = this;
    if (!start.isExist || !end.isExist) return "";

    return `${format(start, "YYYY/MM/DD")} - ${format(end, "YYYY/MM/DD")}`;
  }
}
