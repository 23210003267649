import { applicationType } from "@/const/application_type";

export class ApplicationType {
  constructor(value) {
    this.value = value;
  }

  static empty() {
    return ApplicationType.valueOf(null);
  }
  static valueOf(value = 0) {
    return new ApplicationType(value);
  }

  static payment() {
    return ApplicationType.valueOf("payment");
  }
  static invoicing() {
    return ApplicationType.valueOf("invoicing");
  }

  get isPayment() {
    return this.value === "payment";
  }

  get isInvoicing() {
    return this.value === "invoicing";
  }
  // TODO: Ph1で社員申請に対応
  // get isEmployee() {
  //   return this.value === "employee"
  // }

  updateType(value) {
    return value ? ApplicationType.valueOf(value) : ApplicationType.empty();
  }

  toString() {
    const { name } = applicationType[this.value];
    return name || this.value;
  }

  get isEmpty() {
    return !this.value;
  }
  get style() {
    const { value } = this;
    if (!applicationType[value]) return "";

    return applicationType[value].style;
  }
}
