import { Application } from "@/model/application/application";

export default {
  namespaced: true,
  state: {
    application: Application.empty(),
    pendingApproveIds: [],
  },
  mutations: {
    updateApplication: (state, { application }) =>
      (state.application = application),
    updateApplicationCondition: (state, { applicationCondition }) => {
      state.application = Application.valueOf({ applicationCondition });
    },
    updateApprovalDeadline: (state, { approvalDeadline }) => {
      state.application.approvalDeadline = approvalDeadline;
      state.updated;
    },
    updateComment: (state, { comment }) =>
      (state.application.comment = comment),
    update: (state, id) =>
      (state.pendingApproveIds = [
        ...new Set([...state.pendingApproveIds, id]),
      ]),
    updateApproved: (state, id) =>
      (state.pendingApproveIds = state.pendingApproveIds.filter(
        (stateId) => stateId !== id,
      )),
    clear: (state) => (state.application = Application.empty()),
  },
  actions: {},
  getters: {
    application: (state) => state.application,
    comment: (state) => state.application.comment,
    pendingApproveIds: (state) => state.pendingApproveIds,
  },
};
