import { accountType } from "@/const/file_upload";
import { Date } from "@/model/values/date";

const properties = {
  id: 0,
  companyName: "",
  displayCompanyName: "",
  zipCode: "",
  address1: "",
  address2: "",
  address3: "",
  corporatePhoneNumber: "",
  registeredAt: Date.empty(),
  leavedAt: Date.empty(),
  useStartAt: Date.empty(),
  useStopAt: Date.empty(),
  isUseWorkflow: false,
  numberOfApprovalStep: 0,
  numberOfEmployees: 0,
  isValidB2bCoinToken: false,
  status: "",
  privacySettings: {
    searchByAccountNumber: false,
    searchByCellphoneNumber: false,
    searchByEmployeeNumber: false,
  },
  biometricsSettings: {
    atLogin: false,
    atPayment: false,
  },
  defaultDeadlineDays: 7,
  transferFeeSettings: {
    toGmoaFee: 0,
    toNonGmoaFee: 0,
  },
  bankAccountSettings: {
    accountNameKana: "",
    accountNumber: "",
    accountType: accountType[0],
    bankCode: "",
    branchCode: "",
  },
  isMftMember: false,
  ganbStatus: null,
};

export class Member {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new Member({ ...properties, ...items });
  }
  static empty() {
    return Member.valueOf();
  }

  get displayMemberId() {
    return String(this.id).padStart(7, "0");
  }

  get addressFull() {
    const { zipCode, address1, address2, address3 } = this;
    return `${zipCode ? `〒${zipCode}` : ""} ${address1 ?? ""} ${
      address2 ?? ""
    } ${address3 ?? ""}`;
  }
}
