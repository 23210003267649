import { paymentDetailStatus } from "@/const/payment_detail_status";

export class PaymentDetailStatus {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value = "") {
    return new PaymentDetailStatus(value);
  }
  static empty() {
    return PaymentDetailStatus.valueOf("");
  }

  toString() {
    const { value } = this;
    return paymentDetailStatus[value]?.name || "-";
  }

  get style() {
    const { value } = this;
    return paymentDetailStatus[value]?.style || "";
  }

  get name() {
    const { value } = this;
    return paymentDetailStatus[value]?.name || "";
  }

  get isEmpty() {
    return !this.value;
  }
}
