export const status = {
  registration_pending: {
    value: "registration_pending",
    name: "登録待ち",
    style: "bg",
  },
  registration_completed: {
    value: "registration_completed",
    name: "登録完了",
    style: "bg",
  },
  deleted: { value: "deleted", name: "削除", style: "bg" },
  account_locked: { value: "account_locked", name: "ロック", style: "bg" },
};
