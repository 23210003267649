import { status } from "./status";

// applying
export const transitionFromApplyingByApplicant = [
  status.withdraw,
  status.get_back,
];

export const transitionFromApplyingByApprover = [
  status.remand,
  status.rejected,
  status.approval,
];

export const transitionFromApplyingByFirstApprover = [
  status.remand,
  status.rejected,
  status.first_approval,
];

// get_back
export const transitionFromGetBackByApplicant = [
  status.withdraw,
  status.applying,
];

// remand
export const transitionFromRemandByApplicant = [
  status.withdraw,
  status.applying,
];

// first_approval
export const transitionFromFirstApprovalByApplicant = [
  status.get_back,
  status.withdraw,
];

export const transitionFromFirstApprovalByFirstApprover = [status.get_back];

export const transitionFromFirstApprovalBySecondeApprover = [
  status.remand,
  status.rejected,
  status.second_approval,
];

export const transitionFromPaymentReservationByApprover = [status.canceled];
